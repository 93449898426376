import React from "react";
import {RouteComponentProps} from "react-router";
import {Col} from "react-bootstrap";
import {Helmet} from "react-helmet";

const NotFound = (props: RouteComponentProps) => {
    return (<Col>
        <Helmet>
            <title>Tiny Country Tutoring</title>
        </Helmet>
        <h1>Not Found</h1>
        <p>
            The page <a href={props.location.pathname}>{props.location.pathname}</a> could not be found. Please check
            the URL and try again.
        </p>
    </Col>);
};

export default NotFound;
