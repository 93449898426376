import {AccountActionTypes} from '../actions/acctionTypes';

export interface AccountState {
    is_logged_in: boolean;
    is_logging_in: boolean;
    user_data: Users.UserDTO | null;
    is_registered_tutor: boolean;
    tutor_profile: Tutors.TutorEntityDTO | null;
    csrf_token: string | null;
    is_expired_session: boolean;
}

const initial_state: AccountState = {
    is_logged_in: false,
    is_logging_in: false,
    user_data: null,
    is_registered_tutor: false,
    tutor_profile: null,
    csrf_token: null,
    is_expired_session: false,
};

const set_logging_in = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_logging_in: action.is_logging_in
});

const set_user_data = (state: AccountState, action: any): AccountState => ({
    ...state,
    user_data: action.user_data
});

const set_is_tutor = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_registered_tutor: action.is_registered_tutor
});

const set_tutor_data = (state: AccountState, action: any): AccountState => ({
    ...state,
    tutor_profile: action.tutor_profile,
    user_data: !!action.tutor_profile ? action.tutor_profile.user : state.user_data
});

const set_logged_in = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_logged_in: action.is_logged_in
});

const set_csrf_token = (state: AccountState, action: any): AccountState => ({
    ...state,
    csrf_token: action.csrf_token
});

const set_is_expired_session = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_expired_session: action.is_expired_session
});

const reducer = (state = initial_state, action: any): AccountState => {
    switch (action.type) {
        case AccountActionTypes.SET_LOGGING_IN:
            return set_logging_in(state, action);
        case AccountActionTypes.SET_USER_DATA:
            return set_user_data(state, action);
        case AccountActionTypes.SET_IS_TUTOR:
            return set_is_tutor(state, action);
        case AccountActionTypes.SET_TUTOR_DATA:
            return set_tutor_data(state, action);
        case AccountActionTypes.SET_LOGGED_IN:
            return set_logged_in(state, action);
        case AccountActionTypes.SET_CSRF_TOKEN:
            return set_csrf_token(state, action);
        case AccountActionTypes.SET_IS_EXPIRED_SESSION:
            return set_is_expired_session(state, action);
        default:
            break;
    }
    return state;
};

export default reducer;
