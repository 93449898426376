import {Func} from "../data/Func";

export const requiredText: Func<[string], boolean> = (input: string) => input.trim().length > 0;
export const requiredNumber: Func<[any], boolean> = (input: any) => !isNaN(input);
export const minValue: Func<[number], Func<[any], boolean>> = (min: number) => ((input: any) => Number(input) >= min);
export const isEmail: Func<[string], boolean> = (input: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input);
export const notEqual : Func<[any], Func<[any], boolean>> = (value: any) => ((input: any) => value !== input);

export const combineAnd = <FilterType> (a: Func<[FilterType], boolean>, b: Func<[FilterType], boolean>) : Func<[FilterType], boolean> => (
    input => a(input) && b(input)
);

export const combineSeveralAnd = <FilterType> (...validators: Func<[FilterType], boolean>[]) : Func <[FilterType], boolean> => {
    return (input : any) : boolean => {
        if (validators.length === 0) return true;
        let result : boolean = true;
        for (let i : number = 0; i < validators.length && result; i++) {
            result = result && validators[i](input);
        }
        return result;
    };
};
