import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Col} from "react-bootstrap";
import moment from "moment";

import * as actions from "../../store/actions";
import PagedEntityList from "../../components/PagedEntityList/PagedEntityList";
import {calculate_session_derived_fields} from "../../data/Session";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../api/ManagerAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

interface IManagerSessionsProps extends RouteComponentProps, ConnectedProps<typeof connector> {

}

const ManagerSessions = (props: IManagerSessionsProps) => {

    const [sessions, set_sessions] = useState<Sessions.TutoringSessionEntityDTO[]>([]);

    const [current_page, set_current_page] = useState<number>(0);

    const [max_page, set_max_page] = useState<number>(0);

    const [has_more, set_has_more] = useState<boolean>(true);

    const [loading, set_loading] = useState<boolean>(true);

    const load_page = async (page: number) => {
        if (!loading) set_loading(true);
        try {
            const list = await ManagerAPI.sessionsPage({
                pageSize: Number(process.env.REACT_APP_PAGE_SIZE),
                page: page
            });
            if (max_page <= list.page) {
                set_max_page(list.page);
                set_has_more(list.hasMore);
            }
            set_current_page(list.page);
            set_sessions(list.items);
        } catch (e) {
            props.set_error('Unable to load sessions.', e);
        }
        set_loading(false);
    };

    useEffect(() => {
        load_page(current_page).then(() => set_loading(false));
        // eslint-disable-next-line
    }, []);

    const render_table_heading = () => (
        <tr>
            <th>Student</th>
            <th>Subject</th>
            <th>Tutor</th>
            <th>Start</th>
            <th>End</th>
            <th>Duration</th>
            <th>Price</th>
        </tr>
    );

    const render_session_row = (session: Sessions.TutoringSessionEntityDTO, index: number) => {
        const {duration_hours, amount_owed_cents} = calculate_session_derived_fields(session);
        return (
            <tr
                key={index}
                className={'clickable-row'}
                onClick={() => props.history.push(`/manager/sessions/${session.sessionId}`)}>
                <td>{session.student.firstName} {session.student.lastName}</td>
                <td>{session.subject}</td>
                <td>{session.tutor.user.firstName} {session.tutor.user.lastName}</td>
                <td>{moment(session.startTime).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{moment(session.endTime).format('MM/DD/YYYY hh:mm A')}</td>
                <td>{duration_hours.toFixed(2)}h</td>
                <td>${(amount_owed_cents / 100).toFixed(2)}</td>
            </tr>
        )
    }

    return (
        <Col>
            <Helmet>
                <title>My Sessions | Tiny Country Tutoring</title>
            </Helmet>
            <h1>My Sessions</h1>
            <PagedEntityList<Sessions.TutoringSessionEntityDTO>
                items={sessions}
                current_page={current_page}
                max_page={max_page}
                has_more={has_more}
                loading={loading}
                load_page={load_page}
                render_table_heading={render_table_heading}
                render_item_row={render_session_row}/>
        </Col>
    );
};

export default connector(ManagerSessions);
