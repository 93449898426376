import React from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";

import SelectButtonInputType from "../../../data/ui/input/SelectButtonInputType";
import {SelectOption} from "../../../data/ui/input/SelectInputType";

interface ISelectButtonElementProps {
    element: SelectButtonInputType<any>;

    changed(event: any): void;
}

const SelectButtonElement = (props: ISelectButtonElementProps) => {
    const on_option_select = (event: any, selected_value: any) => {
        event.preventDefault();
        props.changed({target: {value: selected_value}});
    };
    const render_option = (option: SelectOption<any>, index: number) => (
        <Dropdown.Item
            key={index}
            onClick={(event: any) => on_option_select(event, option.value)}>{option.name}</Dropdown.Item>
    );
    return (
        <DropdownButton
            id={props.element.id}
            title={props.element.selected_value_to_string(props.element.value)}
            variant={props.element.variant}>
            {props.element.options.map(render_option)}
        </DropdownButton>
    );
};

export default SelectButtonElement;
