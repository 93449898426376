import React, {useEffect} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import * as actions from '../../store/actions';
import StateType from "../../store/StateType";
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import ErrorBanner from "../../components/ErrorAlert/ErrorAlert";
import TopBar from "../../components/TopBar/TopBar";
import MainRouter from "../MainRouter/MainRouter";

const mapStateToProps = (state: StateType) => ({
    is_loading: state.common.is_loading,
    is_logging_in: state.account.is_logging_in,
    is_logged_in: state.account.is_logged_in,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        initial_load_account_data: () => dispatch(actions.initial_load_account_data())
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const App = (props: PropsFromRedux) => {

    useEffect(() => {
        props.initial_load_account_data();
        // eslint-disable-next-line
    }, []);

    if (props.is_loading) {
        return (<LoadingScreen/>);
    }

    return (
        <>
            {props.is_logged_in ? <TopBar/> : null}
            {props.is_logged_in ? <ErrorBanner/> : null}
            <Container fluid>
                <Row>
                    <Col>
                        <MainRouter/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default connector(App);
