import React from "react";
import {Table} from "react-bootstrap";
import DynamicPagination from "../DynamicPagination/DynamicPagination";

interface IPagedEntityListProps<EntityType> {
    items: EntityType[];
    current_page: number;
    max_page: number;
    has_more: boolean;
    loading: boolean;

    load_page(page: number): Promise<void>;

    render_table_heading(): JSX.Element;

    render_item_row(item: EntityType, index: number): JSX.Element;
}

const PagedEntityList = <EntityType, >(props: IPagedEntityListProps<EntityType>) => {

    const load_previous_page = async () => {
        if (props.current_page === 0) return;
        await props.load_page(props.current_page - 1);

    };

    const load_next_page = async () => {
        if (props.current_page === props.max_page && !props.has_more) return;
        await props.load_page(props.current_page + 1);
    };

    return (<>
        <Table responsive striped>
            <thead>
            {props.render_table_heading()}
            </thead>
            <tbody>
            {props.items.map(props.render_item_row)}
            </tbody>
        </Table>
        <DynamicPagination
            disabled={props.loading}
            current_page={props.current_page}
            max_page={props.max_page}
            has_more={props.has_more}
            onPreviousPage={load_previous_page}
            onNextPage={load_next_page}
            loadPage={props.load_page}/>
    </>);
};

export default PagedEntityList;
