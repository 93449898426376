import React, {FormEvent, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Button, Col, Form, Row} from "react-bootstrap";

import * as actions from '../../store/actions';
import * as forms from '../../util/forms';
import * as validators from '../../util/validators';
import CurrencyInputType from "../../data/ui/input/CurrencyInputType";
import InputType, {InputTypes} from "../../data/ui/input/InputType";
import InputElement from "../../components/InputElement/InputElement";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {Helmet} from "react-helmet";
import TutorAPI from "../../api/TutorAPI";
import WebAPI from "../../api/WebAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_tutor_profile: (tutor: Tutors.TutorEntityDTO) => dispatch(actions.set_tutor_profile(tutor)),
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e)),
    set_page_error: (message: string) => dispatch(actions.set_error_message(message))
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IRegisterProps extends PropsFromRedux, RouteComponentProps {

}

interface IRegisterForm extends Record<string, InputType> {
    hourly_rate: CurrencyInputType
}

const Register = (props: IRegisterProps) => {

    const [register_form, set_register_form] = useState<IRegisterForm>({
        hourly_rate: {
            type: InputTypes.Currency,
            allow_negative: false,
            label: 'Default Hourly Rate',
            step: 1,
            value: 15,
            valid: true,
            touched: true,
            validator: validators.minValue(0)
        }
    });
    const [register_form_valid, set_register_form_valid] = useState<boolean>(true);
    const [form_submitting, set_form_submitting] = useState<boolean>(false);

    const register_form_on_change = (event: any, key: string) => {
        const {form, form_valid} = forms.update_form(event, key, register_form);
        set_register_form(form as IRegisterForm);
        set_register_form_valid(form_valid);
    };

    const register_form_on_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (form_submitting) return;
        const {form, form_valid} = forms.filter_and_validate_form(register_form);
        const final_form = form as IRegisterForm;
        if (!form_valid) {
            set_register_form(final_form);
            set_register_form_valid(form_valid);
            return;
        }
        set_form_submitting(true);
        try {
            const tutor_profile = await TutorAPI.register({
                defaultPriceCents: final_form.hourly_rate.value * 100
            });
            props.set_tutor_profile(tutor_profile);
            props.history.push('/tutor/students');
        } catch (e) {
            props.set_page_error(WebAPI.errorMessage(e, 'Error registering as a tutor'))
        } finally {
            set_form_submitting(false);
        }
    };

    if (form_submitting) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    return (
        <Col>
            <Helmet>
                <title>Register as a Tutor | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Register as a Tutor</h1>
            <Row>
                <Col xs={12} md={6}>
                    <Form onSubmit={register_form_on_submit}>
                        <InputElement
                            element={register_form.hourly_rate}
                            changed={(event: any) => register_form_on_change(event, 'hourly_rate')}/>
                        <Button
                            className={'mt-3'}
                            type={'submit'}
                            variant={'success'}
                            disabled={form_submitting || !register_form_valid}>Register</Button>
                    </Form>
                </Col>
            </Row>
        </Col>
    );
};

export default connector(Register);
