import moment from "moment";

export const session_list_with_session_removed = (sessions: Sessions.TutoringSessionEntityDTO[], session_to_remove: Sessions.TutoringSessionEntityDTO) => {
    return [...sessions].filter(session => session.sessionId !== session_to_remove.sessionId);
};

export const calculate_session_derived_fields = (session: Sessions.TutoringSessionEntityDTO) => {
    const duration_hours: number = moment.duration(
        moment(session.endTime).diff(moment(session.startTime))
    ).asHours();
    const amount_owed_cents = (duration_hours * session.rateCents);
    return {duration_hours, amount_owed_cents};
};

export const calculate_session_list_totals = (sessions: Sessions.TutoringSessionEntityDTO[]) => {
    let total_duration_hours: number = 0;
    let total_amount_owed_cents: number = 0;
    for (const session of sessions) {
        const {duration_hours, amount_owed_cents} = calculate_session_derived_fields(session);
        total_duration_hours += duration_hours;
        total_amount_owed_cents += amount_owed_cents;
    }
    return {total_duration_hours, total_amount_owed_cents};
};

