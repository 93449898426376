import React from "react";
import {Form} from "react-bootstrap";
import EmailInputType from "../../../data/ui/input/EmailInputType";

interface EmailElementProps {
    element: EmailInputType;

    changed(event: any): void;
}

const EmailElement = (props: EmailElementProps) => (
    <Form.Control
        type="email"
        placeholder={props.element.placeholder || props.element.label || ''}
        value={props.element.value}
        onChange={props.changed}
        isInvalid={!props.element.valid && props.element.touched}
        isValid={props.element.valid}/>
);

export default EmailElement;
