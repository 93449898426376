import {useState} from "react";

const useFlatPageMap = <T>(initial_state: Record<number, T[]>): [T[], (page_index: number, page: T[]) => void] => {

    type PageRecord = Record<number, T[]>;

    const [pages, set_pages] = useState<PageRecord>(initial_state);

    const set_page = (page_index: number, page: T[]) => {
        const updated_pages: PageRecord = {...pages};
        updated_pages[page_index] = [...page];
        set_pages(updated_pages);
    };

    const items = Object.keys(pages)
        .map(key => Number(key))
        .sort((a, b) => a - b)
        .flatMap(index => pages[index]);

    return [items, set_page];
};

export default useFlatPageMap;