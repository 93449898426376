import {Button} from "react-bootstrap";
import React from "react";

type Props = {
    children?: React.ReactNode;
    has_more: boolean;
    load_more: () => void;
};

const LoadMoreButton = ({children, has_more, load_more}: Props) => {
    if (!has_more) return null;
    return (
        <Button type={'button'} onClick={() => load_more()}>
            {children ?? 'Load More'}
        </Button>
    )
};

export default LoadMoreButton;