import * as action_types from './acctionTypes';

export const set_loading = (is_loading: boolean) => {
    return (dispatch: any) => {
        dispatch({type: action_types.COMMON_SET_LOADING, is_loading: is_loading});
    };
};

export const set_error_message = (error_message: string) => {
    return (dispatch: any) => {
        dispatch({type: action_types.COMMON_SET_ERROR_MESSAGE, error_message: error_message});
    };
};

export const set_error_from_response = (default_message: string, error: any) => {
    return (dispatch: any) => {
        let error_message = default_message;
        if (error && error.response && error.response.data && error.response.data.message) {
            error_message = error.response.data.message;
        }
        dispatch(set_error_message(error_message));
    };
};

export const clear_error = () => {
    return (dispatch: any) => {
        dispatch({type: action_types.COMMON_CLEAR_ERROR});
    };
};
