import React from "react";
import InputType, {InputTypes} from "../../data/ui/input/InputType";
import TextInputType from "../../data/ui/input/TextInputType";
import PasswordInputType from "../../data/ui/input/PasswordInputType";
import {Form} from "react-bootstrap";
import TextElement from "./TextElement/TextElement";
import PasswordElement from "./PasswordElement/PasswordElement";
import SelectElement from "./SelectElement/SelectElement";
import SelectInputType from "../../data/ui/input/SelectInputType";
import DateTimeElement from "./DateTimeElement/DateTimeElement";
import DateTimeInputType from "../../data/ui/input/DateTimeInputType";
import SingleTypeAheadInputElement from "./SingleTypeAheadInputElement/SingleTypeAheadInputElement";
import SingleTypeAheadInputType from "../../data/ui/input/SingleTypeAheadInputType";
import CurrencyInputElement from "./CurrencyInputElement/CurrencyInputElement";
import CurrencyInputType from "../../data/ui/input/CurrencyInputType";
import SelectButtonElement from "./SelectButtonElement/SelectButtonElement";
import SelectButtonInputType from "../../data/ui/input/SelectButtonInputType";
import EmailElement from "./EmailElement/EmailElement";
import EmailInputType from "../../data/ui/input/EmailInputType";
import FileElement from "./FileElement/FileElement";
import {FileInputType} from "../../data/ui/input/FileInputType";

interface InputElementProps {
    element: InputType;
    row?: boolean;

    changed(event: any): void;
}

const InputElement = (props: InputElementProps) => {
    const renderElement = () => {
        switch (props.element.type) {
            case InputTypes.Text:
                return (<TextElement element={props.element as TextInputType} changed={props.changed}/>);
            case InputTypes.Password:
                return (<PasswordElement element={props.element as PasswordInputType} changed={props.changed}/>);
            case InputTypes.Select:
                return (<SelectElement element={props.element as SelectInputType<any>} change={props.changed}/>);
            case InputTypes.DateTime:
                return (<DateTimeElement element={props.element as DateTimeInputType} change={props.changed}/>);
            case InputTypes.SingleTypeAhead:
                return (<SingleTypeAheadInputElement
                    element={props.element as SingleTypeAheadInputType} onChange={props.changed}/>);
            case InputTypes.Currency:
                return (<CurrencyInputElement element={props.element as CurrencyInputType} onChange={props.changed}/>);
            case InputTypes.SelectButton:
                return (<SelectButtonElement
                    element={props.element as SelectButtonInputType<any>} changed={props.changed}/>);
            case InputTypes.Email:
                return <EmailElement element={props.element as EmailInputType} changed={props.changed}/>;
            case InputTypes.File:
                return <FileElement element={props.element as FileInputType} changed={props.changed}/>;
            default:
                return null;
        }
    };
    return (
        <Form.Group>
            {props.element.label ? <Form.Label>{props.element.label}</Form.Label> : null}
            {renderElement()}
        </Form.Group>
    );
};

export default InputElement;
