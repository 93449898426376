import React from "react";
import {Pagination} from "react-bootstrap";

import classes from './DynamicPagination.module.css';

interface IDynamicPaginationProps {
    disabled?: boolean;
    current_page: number;
    max_page: number;
    has_more: boolean;
    onPreviousPage() : void;
    onNextPage(): void;
    loadPage(page: number): void;
}

const DynamicPagination = (props: IDynamicPaginationProps) => {
    const render_page_items = () => {
        const page_items: any[] = [];
        for (let page_num = 0; page_num <= props.max_page; page_num++) {
            page_items.push((
                <Pagination.Item
                    disabled={props.disabled}
                    key={page_num}
                    active={page_num === props.current_page}
                    onClick={() => props.loadPage(page_num)}>{page_num + 1}</Pagination.Item>
            ));
        }
        return page_items;
    };
    return (
        <Pagination className={classes.DynamicPagination}>
            <Pagination.First onClick={() => props.loadPage(0)} disabled={props.disabled}/>
            <Pagination.Prev onClick={() => props.onPreviousPage()} disabled={props.disabled || props.current_page === 0}/>
            {render_page_items()}
            {props.has_more ? <Pagination.Item
                onClick={() => props.onNextPage()}
                disabled={props.disabled}>...</Pagination.Item> : null}
            <Pagination.Next onClick={() => props.onNextPage()}
                             disabled={props.disabled || (props.current_page === props.max_page && !props.has_more)}/>
            <Pagination.Last onClick={() => props.loadPage(props.max_page)} disabled={props.disabled}/>
        </Pagination>
    )
};

export default DynamicPagination;
