import React from "react";
import {Button, Card, Col, Image, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import styles from './UserCard.module.css';

interface IUserCardProps {
    user: Users.UserDTO,
    title?: string
}

const UserCard = (props: IUserCardProps) => {
    const image_prefix = 'https://tiny-country-games-public-files.s3.us-west-2.amazonaws.com/images/profile-pictures/';
    const no_profile = 'no-profile-img.png';
    const img_src = `${image_prefix}${props.user.profileImg ? `${props.user.profileImg}` : no_profile}`;
    return (<Card>
        {props.title ? <Card.Header>{props.title}</Card.Header> : null}
        <Card.Body>
            <Row>
                <Col xs={4}>
                    <Image fluid src={img_src} className={styles.ProfileImage}/>
                </Col>
                <Col>
                    <div className={[styles.ProfileName].join(' ')}>
                        {props.user.firstName} {props.user.lastName}
                    </div>
                    <Button
                        variant={'primary'}
                        href={`mailto:${props.user.email}`}>
                        <FontAwesomeIcon className={'me-1'} icon={faEnvelope}/>Email
                    </Button>
                </Col>
            </Row>
        </Card.Body>
    </Card>);
};

export default UserCard;
