import moment from "moment";
import {calculate_session_list_totals} from "./Session";

export const calculate_payment_derived_fields = (payment: Payments.TutoringPaymentEntityDTO) => {
    return calculate_session_list_totals(payment.sessions);
};

export const payment_audit_trail_sorted = (audit_trail: Payments.TutoringPaymentAuditTrailEntity[]) => {
    const sorted_audit_trail = [...audit_trail];
    sorted_audit_trail.sort(
        (a, b) => moment(b.updateTime).diff(moment(a.updateTime))
    );
    return sorted_audit_trail;
};

export const payment_creation_date = (payment: Payments.TutoringPaymentEntityDTO) => {
    const audit_trail = payment_audit_trail_sorted(payment.auditTrail);
    return audit_trail[0].updateTime;
};

export const payment_students = (payment: Payments.TutoringPaymentEntityDTO) => {
    const student_set = new Set<string>();
    const students: string[] = [];
    for (const session of payment.sessions) {
        const student_name = `${session.student.firstName} ${session.student.lastName}`;
        if (!student_set.has(student_name)) {
            student_set.add(student_name);
            students.push(student_name);
        }
    }
    return students;
};
