import React from "react";
import moment from "moment";

import {calculate_session_derived_fields} from "../../data/Session";
import TitleValuePair from "../../data/ui/TitleValuePair";
import TitleValuePairRow from "../TitleValuePairRow/TitleValuePairRow";

interface ISessionDetailsProps {
    session: Sessions.TutoringSessionEntityDTO;
}

const SessionDetails = (props: ISessionDetailsProps) => {
    const {session} = props;
    const {duration_hours, amount_owed_cents} = calculate_session_derived_fields(session);
    const session_values: TitleValuePair[] = [
        {title: 'Student', value: `${session.student.firstName} ${session.student.lastName}`},
        {title: 'Subject', value: session.subject},
        {title: 'Tutor', value: `${session.tutor.user.firstName} ${session.tutor.user.lastName}`},
        {title: 'Start Time', value: moment(session.startTime).format('MM/DD/YYYY hh:mm a')},
        {title: 'End Time', value: moment(session.endTime).format('MM/DD/YYYY hh:mm a')},
        {title: 'Duration', value: `${duration_hours.toFixed(2)} h`},
        {title: 'Amount Owed', value: `$${(amount_owed_cents / 100).toFixed(2)}`}
    ];
    return (
        <>
            {session_values.map((session_value, index) => (
                <TitleValuePairRow title={session_value.title} value={session_value.value} key={index} />)
            )}
        </>
    );
};

export default SessionDetails;

