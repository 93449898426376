import React from "react";
import {Table} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadMoreButton from "../LoadMoreButton/LoadMoreButton";

export type InfiniteScrollingTableProps<T> = {
    items: T[];
    load_more: () => void;
    has_more: boolean;
    thead?: React.ReactNode;
    item_row: (item: T) => React.ReactNode;
};

const InfiniteScrollingTable = <T extends any>(props: InfiniteScrollingTableProps<T>) => {
    const {load_more, items, has_more, item_row, thead} = props;
    return (
        <>
            <InfiniteScroll next={load_more}
                            hasMore={has_more}
                            loader={<></>}
                            dataLength={items.length}>
                <Table striped responsive>
                    {thead}
                    <tbody>
                    {items.map(item => item_row(item))}
                    </tbody>
                </Table>
            </InfiniteScroll>
            <LoadMoreButton has_more={has_more} load_more={load_more}/>
        </>
    )
};

export default InfiniteScrollingTable;