import WebAPI from "./WebAPI";
import {Pagination} from "../data/Pagination";
import axios from "axios";

export default class ManagerAPI extends WebAPI {

    static async getInvites(): Promise<StudentManagers.StudentManagerInviteEntityDTO[]> {
        return (await axios.get<StudentManagers.StudentManagerInviteEntityDTO[]>('/tutoring/manager/invites')).data;
    }

    static async respondToInvite(request: StudentManagers.AcceptRejectInviteRequest): Promise<void> {
        await axios.post('/tutoring/manager/invites/respond', request);
    }

    static async getManagerStudents(): Promise<StudentManagers.ManagerStudentEntityDTO[]> {
        return (await axios.get<StudentManagers.ManagerStudentEntityDTO[]>('/tutoring/manager/students')).data;
    }

    static async getManagerStudent(studentId: number): Promise<StudentManagers.ManagerStudentEntityDTO> {
        return (await axios.get<StudentManagers.ManagerStudentEntityDTO>(`/tutoring/manager/students/student/${studentId}`)).data;
    }

    static async sessionsPage(request: ListPageRequest): Promise<Sessions.TutoringSessionEntityDTOPagedList> {
        return (await axios.get<Sessions.TutoringSessionEntityDTOPagedList>(`/tutoring/manager/sessions?${Pagination.listPageRequestToParams(request)}`)).data;
    }

    static async getSession(sessionId: number): Promise<Sessions.TutoringSessionPageModel> {
        return (await axios.get<Sessions.TutoringSessionPageModel>(`/tutoring/manager/sessions/session/${sessionId}`)).data;
    }

    static async paymentsPage(request: ListPageRequest): Promise<Payments.TutoringPaymentEntityDTOPaginatedList> {
        return (await axios.get<Payments.TutoringPaymentEntityDTOPaginatedList>(`/tutoring/manager/payments?${Pagination.listPageRequestToParams(request)}`)).data;
    }

    static async loadPayment(paymentId: number): Promise<Payments.TutoringPaymentEntityDTO> {
        return (await axios.get<Payments.TutoringPaymentEntityDTO>(`/tutoring/manager/payments/payment/${paymentId}`)).data;
    }

}
