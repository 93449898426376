export const COMMON_SET_LOADING = 'COMMON_SET_LOADING';
export const COMMON_SET_ERROR_MESSAGE = 'COMMON_SET_ERROR_MESSAGE';
export const COMMON_CLEAR_ERROR = 'COMMON_CLEAR_ERROR';

export namespace AccountActionTypes {
    export const SET_LOGGING_IN = 'ACCOUNT/SET_LOGGING_IN';
    export const SET_EXPIRATION_TIME = 'ACCOUNT/SET_EXPIRATION_TIME';
    export const SET_USER_DATA = 'ACCOUNT/SET_USER_DATA';
    export const SET_IS_TUTOR = 'ACCOUNT/SET_IS_TUTOR';
    export const SET_TUTOR_DATA = 'ACCOUNT/SET_TUTOR_DATA';
    export const SET_LOGGED_IN = 'ACCOUNT/SET_LOGGED_IN';
    export const SET_CSRF_TOKEN = "ACCOUNT/SET_CSRF_TOKEN";
    export const SET_IS_EXPIRED_SESSION = "ACCOUNT/SET_IS_EXPIRED_SESSION";
}
