import React, {useEffect} from "react";
import {Col} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";

import * as actions from '../../store/actions';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

const map_dispatch_to_props = (dispatch: any) => ({
    logout: () => dispatch(actions.logout())
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ILogoutProps extends PropsFromRedux, RouteComponentProps {

}

const Logout = (props: ILogoutProps) => {

    useEffect(() => {
        props.logout();
        props.history.push('/');
        // eslint-disable-next-line
    }, []);

    return (
        <Col>
            <LoadingScreen/>
        </Col>
    )
};

export default connector(Logout);
