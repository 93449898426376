import * as action_types from '../actions/acctionTypes';

export interface CommonState {
    is_loading: boolean;
    error_message: string | null
}

const initial_state: CommonState = {
    is_loading: true,
    error_message: null
};

const set_loading = (state: CommonState, action: any): CommonState => ({
    ...state,
    is_loading: action.is_loading
});

const set_error_message = (state: CommonState, action: any): CommonState => ({
    ...state,
    error_message: action.error_message
});

const clear_error = (state: CommonState, action: any): CommonState => ({
    ...state,
    error_message: null
});

const reducer = (state = initial_state, action: any): CommonState => {
    switch (action.type) {
        case action_types.COMMON_SET_LOADING:
            return set_loading(state, action);
        case action_types.COMMON_SET_ERROR_MESSAGE:
            return set_error_message(state, action)
        case action_types.COMMON_CLEAR_ERROR:
            return clear_error(state, action);
        default:
            break;
    }
    return state;
};

export default reducer;
