import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps, useParams} from "react-router";
import {Accordion, Button, Col} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import * as actions from "../../../store/actions";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import SessionDetails from "../../../components/SessionDetails/SessionDetails";
import PaymentBasicDetails from "../../../components/PaymentBasicDetails/PaymentBasicDetails";
import SessionAttachmentItem from "../../../components/SessionAttachmentItem/SessionAttachmentItem";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../../api/ManagerAPI";

const mapDispatchToProps = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, mapDispatchToProps);

interface IManagerSessionPage extends ConnectedProps<typeof connector>, RouteComponentProps {
}

const ManagerSessionPage = (props: IManagerSessionPage) => {

    const params = useParams<{ session_id?: string | undefined }>();
    const [loading, set_loading] = useState<boolean>(true);
    const [session, set_session] = useState<Sessions.TutoringSessionEntityDTO | null>(null);
    const [attachments, set_attachments] = useState<Sessions.SessionAttachmentEntity[]>([]);
    const [payment, set_payment] = useState<Payments.TutoringPaymentEntityDTO | null>(null);

    const load_session = async (session_id: number) => {
        const post_data = await ManagerAPI.getSession(session_id);
        set_session(post_data.session);
        set_attachments(post_data.sessionAttachments);
        set_payment(post_data.payment);
    };

    useEffect(() => {
        const session_id = Number(params.session_id);
        if (isNaN(session_id)) {
            props.set_error('Invalid session id.', null);
            props.history.push('/manager/sessions');
            return;
        }
        load_session(session_id)
            .then(() => set_loading(false))
            .catch(e => {
                set_loading(false);
                props.set_error('Unable to load session.', e);
                props.history.push('/manager/sessions');
            });
        // eslint-disable-next-line
    }, []);

    if (loading || !session) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const render_payment_details = () => {
        if (!payment) return null;
        return (
            <>
                <h2>Payment</h2>
                <PaymentBasicDetails payment={payment}/>
                <LinkContainer to={`/manager/payments/${payment.paymentId}`}>
                    <Button variant={'primary'}>Payment Details</Button>
                </LinkContainer>
            </>
        );
    };

    const render_attachment = (attachment: Sessions.SessionAttachmentEntity, index: number) => (
        <SessionAttachmentItem attachment={attachment} eventKey={`attachment_${index}`} key={index}/>
    );

    return (
        <Col>
            <Helmet>
                <title>Session Details | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Session Details</h1>
            <SessionDetails session={session}/>
            {render_payment_details()}
            <h2 className={'mt-3'}>Attachments</h2>
            <Accordion>
                {attachments.map(render_attachment)}
            </Accordion>
        </Col>
    );
};

export default connector(ManagerSessionPage);
