import React from "react";
import {calculate_payment_derived_fields} from "../../data/Payment";
import TitleValuePair from "../../data/ui/TitleValuePair";
import TitleValuePairRow from "../TitleValuePairRow/TitleValuePairRow";

interface IPaymentBasicDetailsProps {
    payment: Payments.TutoringPaymentEntityDTO;
}

const PaymentBasicDetails = (props: IPaymentBasicDetailsProps) => {
    const {payment} = props;
    const {total_duration_hours, total_amount_owed_cents} = calculate_payment_derived_fields(payment);
    const payment_values: TitleValuePair[] = [
        {title: 'Tutor', value: `${payment.tutor.user.firstName} ${payment.tutor.user.lastName}`},
        {title: 'Total Sessions', value: `${payment.sessions.length}`},
        {title: 'Payment Method', value: payment.method},
        {title: 'Total Duration', value: `${total_duration_hours.toFixed(2)} h`},
        {title: 'Total Amount Owed', value: `$${(total_amount_owed_cents / 100).toFixed(2)}`},
        {title: 'Payment Status', value: payment.paid ? 'Paid' : 'Unpaid'},
        {title: 'Note', value: payment.note}
    ];
    return (
        <>
            {payment_values.map((payment_value, index) => (
                <TitleValuePairRow title={payment_value.title} value={payment_value.value} key={index}/>
            ))}
        </>
    );
};

export default PaymentBasicDetails;
