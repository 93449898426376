import React from "react";
import {Alert} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";

import * as actions from '../../store/actions';
import StateType from "../../store/StateType";

const mapStateToProps = (state: StateType) => ({
    error_message: state.common.error_message
});

const mapDispatchToProps = (dispatch: any) => ({
    dismiss_error: () => dispatch(actions.clear_error())
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const errorAlert = (props: PropsFromRedux) => (
    <Alert
        variant={"danger"}
        onClose={() => props.dismiss_error()}
        show={!!props.error_message}
        dismissible>
        {props.error_message}
    </Alert>
);

export default connector(errorAlert);
