import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Col, Table} from "react-bootstrap";
import moment from "moment";

import * as actions from '../../store/actions';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../api/ManagerAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

interface IManagerStudentsProps extends RouteComponentProps, ConnectedProps<typeof connector> {
}

const ManagerStudents = (props: IManagerStudentsProps) => {

    const [students, set_students] = useState<StudentManagers.ManagerStudentEntityDTO[]>([]);
    const [loading, set_loading] = useState<boolean>(true);

    const load_students = async () => {
        try {
            const loadedStudents = await ManagerAPI.getManagerStudents();
            set_students(loadedStudents);
        } catch (e) {
            props.set_error('Unable to load students.', e);
        }
    };

    useEffect(() => {
        load_students().then(() => set_loading(false));
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const render_student_table = () => {
        const render_student_row = (student: StudentManagers.ManagerStudentEntityDTO, index: number) => (
            <tr key={index}
                className={'clickable-row'}
                onClick={() => props.history.push(`/manager/students/${student.student.studentId}`)}>
                <td>{student.student.firstName} {student.student.lastName}</td>
                <td>{moment(student.student.registerDate).format('MM/DD/YYYY')}</td>
                <td>{student.student.tutor?.user.firstName} {student.student.tutor?.user.lastName}</td>
                <td>{student.relation}</td>
            </tr>
        );
        return (
            <Table responsive striped>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Register Date</th>
                    <th>Tutor</th>
                    <th>Relation</th>
                </tr>
                </thead>
                <tbody>
                {students.map(render_student_row)}
                </tbody>
            </Table>
        );
    };

    return (
        <Col>
            <Helmet>
                <title>My Students | Tiny Country Tutoring</title>
            </Helmet>
            <h1>My Students</h1>
            {render_student_table()}
        </Col>
    );
}

export default connector(ManagerStudents);
