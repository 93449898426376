import React from "react";
import {Table} from "react-bootstrap";
import moment from "moment";

interface IPaymentAuditTrailTableProps {
    audit_trail: Payments.TutoringPaymentAuditTrailEntity[]
}

const PaymentAuditTrailTable = (props: IPaymentAuditTrailTableProps) => {
    const render_audit_trail_entry_row = (audit_trail_entry: Payments.TutoringPaymentAuditTrailEntity, index: number) => (
        <tr key={index}>
            <td>{moment(audit_trail_entry.updateTime).format('MM/DD/YYYY hh:mm a')}</td>
            <td>{audit_trail_entry.updateDescription}</td>
        </tr>
    );
    return (
        <Table striped responsive>
            <thead>
            <tr>
                <th>Time</th>
                <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {props.audit_trail.map(render_audit_trail_entry_row)}
            </tbody>
        </Table>
    );
};

export default PaymentAuditTrailTable;
