import React from "react";
import {Button, Col} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {LinkContainer} from "react-router-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import * as actions from '../../store/actions';
import {Helmet} from "react-helmet";
import useInfiniteScroller from "../../hooks/useInfiniteScroller";
import TutorPaymentsTable from "../../components/TutorPaymentsTable/TutorPaymentsTable";
import TutorAPI from "../../api/TutorAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IPaymentsProps extends PropsFromRedux, RouteComponentProps {
}

const TutorPayments = (props: IPaymentsProps) => {

    const {
        has_more, set_has_more,
        next_page, set_next_page,
        items: payments, set_item_page: set_payment_page
    } = useInfiniteScroller<Payments.TutoringPaymentEntityDTO>();

    const load_page = async () => {
        const page = next_page;
        try {
            const list = await TutorAPI.paymentsPage({
                page: page,
                pageSize: Number(process.env.REACT_APP_PAGE_SIZE)
            });
            set_payment_page(list.page, list.items);
            set_has_more(list.hasMore);
            set_next_page(page + 1);
        } catch (e) {
            props.set_error('Unable to load payments', e);
        }
    };

    return (
        <Col>
            <Helmet>
                <title>Payments | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Payments</h1>
            <LinkContainer to={'/tutor/create_payment'}>
                <Button
                    type={'button'}
                    variant={'success'}><FontAwesomeIcon icon={faPlus} fixedWidth/>&nbsp;Create Payment</Button>
            </LinkContainer>
            <TutorPaymentsTable
                payments={payments}
                on_payment_click={(payment) => props.history.push(`/tutor/payments/${payment.paymentId}`)}
                load_more={load_page}
                has_more={has_more}
            />
        </Col>
    );
};

export default connector(TutorPayments);
