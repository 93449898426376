import {useState} from "react";
import useFlatPageMap from "./useFlatPageMap";
import {useTriggerScrollFix} from "./useTriggerScrollFix";

const useInfiniteScroller = <T>() => {
    const [has_more, set_has_more] = useState<boolean>(true);
    const [next_page, set_next_page] = useState<number>(0);
    const [items, set_item_page] = useFlatPageMap<T>({});
    useTriggerScrollFix([items.length]);
    return {has_more, set_has_more, next_page, set_next_page, items, set_item_page};
};

export default useInfiniteScroller;