import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {
    faBusinessTime,
    faFileInvoiceDollar,
    faSignOutAlt,
    faUserPlus,
    faUsersCog,
    faUsers,
    faCalendarDay,
    faReceipt
} from "@fortawesome/free-solid-svg-icons";

export interface NavigationDataItem {
    path: string;
    title: string;
    icon: IconDefinition
}

export const common_nav: NavigationDataItem[] = [
    {
        path: '/manager/students',
        title: 'My Students',
        icon: faUsers
    },
    {
        path: '/manager/sessions',
        title: 'My Sessions',
        icon: faCalendarDay
    },
    {
        path: '/manager/payments',
        title: 'My Payments',
        icon: faReceipt
    }
];

export const tutor_nav: NavigationDataItem[] = [
    {
        path: '/tutor/students',
        title: 'Students',
        icon: faUsersCog
    },
    {
        path: '/tutor/sessions',
        title: 'Sessions',
        icon: faBusinessTime
    },
    {
        path: '/tutor/payments',
        title: 'Payments',
        icon: faFileInvoiceDollar
    }
];

const logout: NavigationDataItem = {
    path: '/logout',
    title: 'Log Out',
    icon: faSignOutAlt
};

const register: NavigationDataItem = {
    path: '/register',
    title: 'Register as Tutor',
    icon: faUserPlus
}

export const get_navigation_items = (is_registered_tutor: boolean) => {
    return is_registered_tutor ?
        [...common_nav, ...tutor_nav, logout] :
        [...common_nav, register, logout];
};
