import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps, useParams} from "react-router";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import * as actions from '../../../store/actions';
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import TitleValuePairRow from "../../../components/TitleValuePairRow/TitleValuePairRow";
import UserCard from "../../../components/UserCard/UserCard";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../../api/ManagerAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

interface IManagerStudentProps extends RouteComponentProps, ConnectedProps<typeof connector> {
}

const ManagerStudentPage = (props: IManagerStudentProps) => {

    const params = useParams<{ student_id?: string | undefined }>()

    const [manager_student, set_manager_student] = useState<StudentManagers.ManagerStudentEntityDTO | null>(null);
    const [loading, set_loading] = useState<boolean>(true);

    const load_page_data = async (student_id: number) => {
        const managerStudent = await ManagerAPI.getManagerStudent(student_id);
        set_manager_student(managerStudent);
    }

    useEffect(() => {
        const student_id = Number(params.student_id);
        if (isNaN(student_id)) {
            props.set_error('Invalid student id.', null);
            props.history.push('/manager/students');
            return;
        }
        load_page_data(student_id)
            .then(() => set_loading(false))
            .catch(e => {
                props.set_error('Unable to load student.', e);
                set_loading(false);
                props.history.push('/manager/students');
            })
        // eslint-disable-next-line
    }, [])

    if (loading || !manager_student) {
        return (<Col>
            <LoadingScreen/>
        </Col>);
    }

    return (<Col>
        <Helmet>
            <title>Student Details | Tiny Country Tutoring</title>
        </Helmet>
        <h1>Student Details</h1>
        <TitleValuePairRow
            title={'Name'}
            value={`${manager_student.student.firstName} ${manager_student.student.lastName}`}/>
        <TitleValuePairRow
            title={'Register Date'}
            value={moment(manager_student.student.registerDate).format('MM/DD/YYYY hh:mm A')}/>
        <TitleValuePairRow title={'Relation'} value={manager_student.relation}/>
        {manager_student.student.tutor ? (
            <Row>
                <Col md={6} xs={12}>
                    <UserCard user={manager_student.student.tutor.user} title={'Tutor'}/>
                </Col>
            </Row>
        ) : null}
    </Col>);
};

export default connector(ManagerStudentPage);
