import React, {FormEvent, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Button, Col, Form, Row} from "react-bootstrap";
import moment from "moment";

import StateType from "../../store/StateType";
import * as actions from '../../store/actions/';
import * as forms from '../../util/forms';
import InputType, {InputTypes} from "../../data/ui/input/InputType";
import SelectInputType from "../../data/ui/input/SelectInputType";
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import InputElement from "../../components/InputElement/InputElement";
import DateTimeInputType from "../../data/ui/input/DateTimeInputType";
import SingleTypeAheadInputType from "../../data/ui/input/SingleTypeAheadInputType";
import CurrencyInputType from "../../data/ui/input/CurrencyInputType";
import {Helmet} from "react-helmet";
import TutorAPI from "../../api/TutorAPI";

const map_state_to_props = (state: StateType) => ({
    default_rate_cents: state.account.tutor_profile ? state.account.tutor_profile.defaultPriceCents : 0
});

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e)),
    clear_error: () => dispatch(actions.clear_error())
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ICreateSessionProps extends PropsFromRedux, RouteComponentProps {
}

interface ICreateSessionForm extends Record<string, InputType> {
    student: SelectInputType<Students.StudentEntityDTO>;
    start: DateTimeInputType;
    end: DateTimeInputType;
    subject: SingleTypeAheadInputType;
    rate: CurrencyInputType;
}

const CreateSession = (props: ICreateSessionProps) => {
    const [students, set_students] = useState<Record<string, Students.StudentEntityDTO>>({});
    const [create_form, set_create_form] = useState<ICreateSessionForm>({
        student: {
            type: InputTypes.Select,
            touched: false,
            valid: false,
            label: 'Student',
            value: {studentId: -1, firstName: '', lastName: '', registerDate: '', tutor: null},
            value_to_string: (student: Students.StudentEntityDTO) => student.studentId.toString(10),
            filter: (val: string) => students[val],
            options: []
        },
        start: {
            type: InputTypes.DateTime,
            touched: false,
            valid: true,
            label: 'Session Start',
            value: moment().startOf('minute'),
            date_format: 'MM/DD/YYYY',
            time_format: 'hh:mm A',
            filter: (value: moment.Moment) => moment(value).startOf('minute')
        },
        end: {
            type: InputTypes.DateTime,
            touched: false,
            valid: true,
            label: 'Session End',
            value: moment().startOf('minute'),
            date_format: 'MM/DD/YYYY',
            time_format: 'hh:mm A',
            filter: (value: moment.Moment) => moment(value).startOf('minute')
        },
        subject: {
            type: InputTypes.SingleTypeAhead,
            touched: false,
            valid: false,
            label: 'Subject',
            value: [],
            id: 'subject-select',
            allow_new: true,
            options: []
        },
        rate: {
            type: InputTypes.Currency,
            touched: false,
            valid: true,
            label: 'Hourly Rate',
            value: props.default_rate_cents / 100,
            allow_negative: false,
            step: 1,
            validator: (value: number) => value >= 0
        }
    });
    const [create_form_valid, set_create_form_valid] = useState<boolean>(false);
    const [loading, set_loading] = useState<boolean>(true);
    const [submitting, set_submitting] = useState<boolean>(false);

    const load_data = async () => {
        try {
            const [loaded_students, loaded_subjects] = await Promise.all([
                TutorAPI.students(),
                TutorAPI.subjects(),
            ]);

            if (loaded_students.length === 0) {
                props.set_error('You must have registered at least one student to make a session.', null);
                props.history.push('/tutor/students');
                return;
            }

            const student_record: Record<string, Students.StudentEntityDTO> = {};
            loaded_students.forEach((student: Students.StudentEntityDTO) => student_record[student.studentId.toString()] = student);
            set_students(student_record);


            const updated_create_form = {
                ...create_form
            };

            const updated_student_select = {
                ...updated_create_form.student
            }
            updated_student_select.options = loaded_students.map((student: Students.StudentEntityDTO) => ({
                name: student.firstName + ' ' + student.lastName,
                value: student
            }))
            updated_student_select.value = loaded_students[0];
            updated_student_select.valid = true;
            updated_student_select.filter = (val: string) => student_record[val];
            updated_create_form.student = updated_student_select;

            const updated_subject_select = {
                ...updated_create_form.subject
            };
            updated_subject_select.options = [...loaded_subjects];
            updated_create_form.subject = updated_subject_select;

            set_create_form(updated_create_form);
        } catch (e) {
            props.set_error('Unable to load data.', e);
        }
        set_loading(false);
    };

    useEffect(() => {
        load_data();
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        )
    }

    const on_form_change = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, create_form);
        set_create_form(form as ICreateSessionForm);
        set_create_form_valid(form_valid);
    };

    const on_form_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (submitting) return;
        set_submitting(true);
        const unfiltered_student_form = {...create_form};
        const unfiltered_student = {...unfiltered_student_form.student};
        unfiltered_student.filter = (value: string) => (value as unknown) as Students.StudentEntityDTO;
        unfiltered_student_form.student = unfiltered_student;
        const {form, form_valid} = forms.filter_and_validate_form(unfiltered_student_form);
        const final_form = form as ICreateSessionForm;
        if (!form_valid) {
            set_create_form_valid(form_valid);
            set_create_form(final_form);
            set_submitting(false);
            return;
        }
        props.clear_error();
        const student_id = final_form.student.value.studentId;
        const start_moment = moment(final_form.start.value);
        const end_moment = moment(final_form.end.value);
        const raw_subject = final_form.subject.value[0];
        if (typeof raw_subject === 'undefined') {
            props.set_error('Select a subject', null);
            set_submitting(false);
            return;
        }
        try {
            const new_session = await TutorAPI.createSession({
                studentId: student_id,
                startTime: start_moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                endTime: end_moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                subject: typeof raw_subject === 'string' ? raw_subject : `${raw_subject.label}`,
                rateCents: Math.round(final_form.rate.value * 100)
            });
            props.history.push(`/tutor/sessions/${new_session.sessionId}`);
        } catch (e) {
            props.set_error('Unable to create session.', e);
        }
        set_submitting(false);
    }

    return (
        <Col>
            <Helmet>
                <title>Create Session | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Create Session</h1>
            <Form onSubmit={on_form_submit}>
                <Row className={'mb-2'}>
                    <Col>
                        <InputElement
                            element={create_form.student}
                            changed={(event: any) => on_form_change('student', event)}/>
                    </Col>
                </Row>
                <Row className={'mb-2'}>
                    <Col>
                        <InputElement
                            element={create_form.start}
                            changed={(event: any) => on_form_change('start', event)}/>
                    </Col>
                    <Col>
                        <InputElement
                            element={create_form.end}
                            changed={(event: any) => on_form_change('end', event)}/>
                    </Col>
                </Row>
                <Row className={'mb-2'}>
                    <Col>
                        <InputElement
                            element={create_form.subject}
                            changed={(event: any) => on_form_change('subject', event)}/>
                    </Col>
                </Row>
                <Row className={'mb-2'}>
                    <Col>
                        <InputElement
                            element={create_form.rate}
                            changed={(event: any) => on_form_change('rate', event)}/>
                    </Col>
                </Row>
                <Row className={'mb-2'}>
                    <Col>
                        <Button
                            type={'submit'}
                            disabled={!create_form_valid || submitting}
                            variant={'success'}>Create Session</Button>
                    </Col>
                </Row>
            </Form>
        </Col>
    );
};

export default connector(CreateSession);
