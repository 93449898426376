import React from "react";
import {Button, Col} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {LinkContainer} from "react-router-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import * as actions from '../../store/actions';
import {Helmet} from "react-helmet";
import TutorSessionTable from "../../components/TutorSessionTable/TutorSessionTable";
import useInfiniteScroller from "../../hooks/useInfiniteScroller";
import TutorAPI from "../../api/TutorAPI";


const mapDispatchToProps = (dispatch: any) => ({
    actions: {
        set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
    }
});

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

const TutorSessions = ({actions, history}: Props) => {

    const {
        has_more, set_has_more,
        next_page, set_next_page,
        items: sessions, set_item_page: set_session_page
    } = useInfiniteScroller<Sessions.TutoringSessionEntityDTO>();

    const load_page = async () => {
        const page = next_page;
        try {
            const list = await TutorAPI.sessionsPage({
                pageSize: Number(process.env.REACT_APP_PAGE_SIZE),
                page: page
            });
            set_session_page(list.page, list.items);
            set_has_more(list.hasMore);
            set_next_page(page + 1);
        } catch (e) {
            actions.set_error('Unable to load sessions.', e);
        }
    };

    const load_next_page = async () => {
        if (!has_more) return;
        await load_page();
    };

    const goToSessionPage = (session: Sessions.TutoringSessionEntityDTO) => {
        history.push(`/tutor/sessions/${session.sessionId}`)
    };

    return (
        <Col>
            <Helmet>
                <title>Sessions | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Sessions</h1>
            <LinkContainer to={'/tutor/create_session'}>
                <Button
                    type={'button'}
                    variant={'success'}><FontAwesomeIcon icon={faPlus} fixedWidth/> Create Session</Button>
            </LinkContainer>
            <TutorSessionTable
                sessions={sessions}
                on_session_click={goToSessionPage}
                has_more={has_more}
                load_more={load_next_page}
            />
        </Col>
    );
};

export default connector(TutorSessions);
