import InputType from "../data/ui/input/InputType";

interface IUpdateFormReturnType {
    form: Record<string, InputType>;
    form_valid: boolean
}

const is_form_valid = (form: Record<string, InputType>): boolean => {
    let form_valid: boolean = true;
    for (const key in form) {
        form_valid = form_valid && form[key].valid;
    }
    return form_valid;
}

export const update_form = (event: any, key: string, form: Record<string, InputType>): IUpdateFormReturnType => {
    const updated_form = {
        ...form
    };

    const updated_element = {
        ...form[key]
    };

    updated_element.value = updated_element.filter ? updated_element.filter(event.target.value) : event.target.value;

    updated_element.touched = true;
    updated_element.valid = updated_element.validator ? updated_element.validator(event.target.value) : true;

    updated_form[key] = updated_element;

    const form_valid: boolean = is_form_valid(updated_form);

    updated_form[key] = updated_element;
    return {form: updated_form, form_valid: form_valid};
}

export const filter_and_validate_form = (form: Record<string, InputType>): IUpdateFormReturnType => {
    const updated_form = {
        ...form
    };

    for (const key in updated_form) {
        const updated_element = {
            ...updated_form[key]
        };
        updated_element.value = updated_element.filter ?
            updated_element.filter(updated_element.value) : updated_element.value;
        updated_element.valid = updated_element.validator ? updated_element.validator(updated_element.value) : true;
        updated_form[key] = updated_element;
    }

    const form_valid: boolean = is_form_valid(updated_form);

    return {form: updated_form, form_valid: form_valid};
};
