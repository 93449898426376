import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {LinkContainer} from "react-router-bootstrap";
import {RouteComponentProps} from "react-router";
import {Helmet} from 'react-helmet';
import * as actions from '../../store/actions';
import TutorAPI from "../../api/TutorAPI";
import TutorStudentTable from "../../components/TutorStudentTable/TutorStudentTable";

const mapDispatchToProps = (dispatch: any) => ({
    set_error: (default_message: string, error: any) => dispatch(actions.set_error_from_response(default_message, error))
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IStudentsProps extends PropsFromRedux, RouteComponentProps {
}

const Students = (props: IStudentsProps) => {

    const [students, set_students] = useState<Students.StudentEntityDTO[]>([]);

    const load_students = async () => {
        try {
            const loaded_students = await TutorAPI.students();
            set_students(loaded_students);
        } catch (e: any) {
            props.set_error('Unable to load students.', e);
        }
    };

    useEffect(() => {
        load_students();
        // eslint-disable-next-line
    }, []);

    return (
        <Col>
            <Helmet>
                <title>Students | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Students</h1>
            <LinkContainer to={'/tutor/create_student'}>
                <Button
                    type={'button'}
                    variant={'success'}><FontAwesomeIcon icon={faPlus} fixedWidth/> Add Student</Button>
            </LinkContainer>
            <TutorStudentTable
                students={students}
                onStudentClick={student => props.history.push(`/tutor/students/${student.studentId}`)}
            />
        </Col>
    );
};


export default connector(Students);
