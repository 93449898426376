import {Func} from "../../Func";

export enum InputTypes {
    Text,
    Password,
    Select,
    DateTime,
    SingleTypeAhead,
    Currency,
    SelectButton,
    Email,
    File
}

export default interface InputType {
    type: InputTypes
    value: any;
    touched: boolean;
    valid: boolean;
    label? : string;

    filter? : Func<[any], any>;
    validator? : Func<[any], boolean>;
}
