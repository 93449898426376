import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const LoadingScreen = () => (
    <div className={['mt-5', 'text-center'].join(' ')}>
        <FontAwesomeIcon icon={faSpinner} spin size={'5x'} />
    </div>
);

export default LoadingScreen;
