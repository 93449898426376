import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import axios from "axios";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

import App from './containers/App/App';
import common_reducer from './store/reducers/common';
import account_reducer from './store/reducers/account';

import './index.css';
import {configureStore} from "@reduxjs/toolkit";

import * as AccountActions from './store/actions/account';
import moment from "moment";

const store = configureStore({
    reducer: {
        account: account_reducer,
        common: common_reducer
    },
});

axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(request => {
    const csrfToken = store.getState().account.csrf_token;
    if (csrfToken) {
        request.headers['X-CSRF-Token'] = csrfToken;
    }
    return request;
});
axios.interceptors.response.use(response => {
    const csrfToken = response.headers['x-csrf-token'];
    if (csrfToken) {
        store.dispatch(AccountActions.set_csrf_token(csrfToken))
    }
    const sessionExpired = (() => {
        const sessionExpires = response.headers['x-session-expires'];
        if (!sessionExpires) return false;
        const now = moment();
        const expires = moment(sessionExpires);
        if (!expires.isValid()) return false;
        return now.isAfter(expires);
    })();
    store.dispatch(AccountActions.setIsExpiredSession(sessionExpired));
    return response;
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
