import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Row} from "react-bootstrap";
import {Redirect, Route, Switch} from "react-router";

import StateType from "../../store/StateType";
import DesktopNavigation from "../../components/Navigation/DesktopNavigation/DesktopNavigation";
import MobileNavigation from "../../components/Navigation/MobileNavigation/MobileNavigation";
import Login from '../Login/Login';
import Students from "../Students/Students";
import TutorSessions from '../TutorSessions/TutorSessions'
import TutorSessionPage from "../TutorSessions/TutorSessionPage/TutorSessionPage";
import TutorPayments from "../TutorPayments/TutorPayments";
import TutorPaymentPage from "../TutorPayments/TutorPaymentPage/TutorPaymentPage";
import CreatePayment from "../CreatePayment/CreatePayment";
import CreateSession from "../CreateSession/CreateSession";
import Logout from "../Logout/Logout";
import Register from "../Register/Register";
import CreateStudent from "../CreateStudent/CreateStudent";
import TutorStudentPage from "../TutorStudentPage/TutorStudentPage";
import ManagerInvites from "../ManagerInvites/ManagerInvites";
import ManagerStudentPage from "../ManagerStudents/ManagerStudentPage/ManagerStudentPage";
import ManagerStudents from "../ManagerStudents/ManagerStudents";
import ManagerSessions from "../ManagerSessions/ManagerSessions";
import ManagerSessionPage from "../ManagerSessions/ManagerSessionPage/ManagerSessionPage";
import ManagerPayments from "../ManagerPayments/ManagerPayments";
import ManagerPaymentPage from "../ManagerPayments/ManagerPaymentPage/ManagerPaymentPage";
import NotFound from "../NotFound/NotFound";

const mapStateToProps = (state: StateType) => ({
    is_logged_in: state.account.is_logged_in,
    is_registered_tutor: state.account.is_registered_tutor,
    is_expired_session: state.account.is_expired_session
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const mainRouter = (props: PropsFromRedux) => {

    if (!props.is_logged_in) {
        return (
            <Login/>
        );
    }

    if (props.is_expired_session) {
        return (
            <Switch>
                <Route path={'/logout'} component={Logout} exact/>
                <Redirect to={'/logout'}/>
            </Switch>
        );
    }

    const tutor_routes = props.is_registered_tutor ? (
        <>
            <Route path={'/tutor/students'} component={Students} exact/>
            <Route path={'/tutor/students/:student_id'} component={TutorStudentPage} exact/>
            <Route path={'/tutor/create_student'} component={CreateStudent} exact/>
            <Route path={'/tutor/sessions'} component={TutorSessions} exact/>
            <Route path={'/tutor/sessions/:session_id'} component={TutorSessionPage} exact/>
            <Route path={'/tutor/create_session'} component={CreateSession} exact/>
            <Route path={'/tutor/payments'} component={TutorPayments} exact/>
            <Route path={'/tutor/create_payment'} component={CreatePayment} exact/>
            <Route path={'/tutor/payments/:payment_id'} component={TutorPaymentPage} exact/>
        </>
    ) : (
        <Redirect to={'/'} from={'/tutor'}/>
    );


    return (
        <>
            <Row className={'mt-3 mb-5'}>
                <DesktopNavigation/>
                <Switch>
                    <Route path={'/'} component={ManagerInvites} exact/>
                    <Route path={'/logout'} component={Logout} exact/>
                    <Route path={'/register'} component={Register} exact/>
                    <Route path={'/manager/students'} component={ManagerStudents} exact/>
                    <Route path={'/manager/students/:student_id'} component={ManagerStudentPage} exact/>
                    <Route path={'/manager/sessions'} component={ManagerSessions} exact/>
                    <Route path={'/manager/sessions/:session_id'} component={ManagerSessionPage} exact/>
                    <Route path={'/manager/payments'} component={ManagerPayments} exact/>
                    <Route path={'/manager/payments/:payment_id'} component={ManagerPaymentPage} exact/>
                    {tutor_routes}
                    <Route component={NotFound}/>
                </Switch>
            </Row>
            <MobileNavigation/>
        </>
    );
};

export default connector(mainRouter);
