import React from "react";
import {calculate_session_derived_fields} from "../../data/Session";
import moment from "moment/moment";
import InfiniteScrollingTable, {InfiniteScrollingTableProps} from "../InfiniteScrollingTable/InfiniteScrollingTable";

type Props = Omit<InfiniteScrollingTableProps<Sessions.TutoringSessionEntityDTO>, 'thead' | 'item_row' | 'items'> & {
    on_session_click: (session: Sessions.TutoringSessionEntityDTO) => void;
    sessions: Sessions.TutoringSessionEntityDTO[];
};

const SessionRow = ({session, on_session_click}: Props & { session: Sessions.TutoringSessionEntityDTO }) => {
    const {duration_hours, amount_owed_cents} = calculate_session_derived_fields(session);
    return (
        <tr className={'clickable-row'} onClick={() => on_session_click(session)}>
            <td>{session.student.firstName} {session.student.lastName}</td>
            <td>{session.subject}</td>
            <td>{moment(session.startTime).format('MM/DD/YYYY hh:mm a')}</td>
            <td>{moment(session.endTime).format('MM/DD/YYYY hh:mm a')}</td>
            <td>{duration_hours.toFixed(2)} h</td>
            <td>${(amount_owed_cents / 100).toFixed(2)}</td>
        </tr>
    )
}

const TutorSessionTable = (props: Props) => {
    const {sessions, load_more, has_more} = props;
    return (
        <InfiniteScrollingTable
            items={sessions}
            load_more={load_more}
            has_more={has_more}
            thead={(
                <thead>
                <tr>
                    <th>Student</th>
                    <th>Subject</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration</th>
                    <th>Amount Owed</th>
                </tr>
                </thead>
            )}
            item_row={session => <SessionRow key={session.sessionId} session={session} {...props} />}
        />
    );
};

export default TutorSessionTable;
