import React, {FormEvent, useEffect, useState} from "react";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps, useParams} from "react-router";
import * as actions from '../../store/actions';
import * as validators from '../../util/validators';
import * as filters from '../../util/filters'
import * as forms from '../../util/forms';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import InputType, {InputTypes} from "../../data/ui/input/InputType";
import TextInputType from "../../data/ui/input/TextInputType";
import InputElement from "../../components/InputElement/InputElement";
import TitleValuePairRow from "../../components/TitleValuePairRow/TitleValuePairRow";
import moment from "moment";
import EmailInputType from "../../data/ui/input/EmailInputType";
import {Helmet} from "react-helmet";
import UserCard from "../../components/UserCard/UserCard";
import TutorAPI from "../../api/TutorAPI";


const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

interface ITutorStudentPageProps extends ConnectedProps<typeof connector>, RouteComponentProps {
}

interface IEditStudentForm extends Record<string, InputType> {
    first_name: TextInputType;
    last_name: TextInputType;
}

interface IInviteManagerForm extends Record<string, InputType> {
    manager_email: EmailInputType;
    relation: TextInputType;
}

const TutorStudentPage = (props: ITutorStudentPageProps) => {

    const params = useParams<{ student_id?: string | undefined }>();

    const [student, set_student] = useState<Students.StudentEntityDTO | null>(null);

    const [manager_invites, set_manager_invites] = useState<StudentManagers.StudentManagerInviteEntityDTO[]>([]);

    const [edit_student_form, set_edit_student_form] = useState<IEditStudentForm>({
        first_name: {
            type: InputTypes.Text,
            valid: false,
            touched: false,
            label: 'First Name',
            value: '',
            validator: validators.requiredText
        },
        last_name: {
            type: InputTypes.Text,
            valid: false,
            touched: false,
            label: 'Last Name',
            value: '',
            validator: validators.requiredText
        }
    });
    const [edit_student_form_valid, set_edit_student_form_valid] = useState<boolean>(false);

    const [invite_manager_form, set_invite_manager_form] = useState<IInviteManagerForm>({
        manager_email: {
            type: InputTypes.Email,
            label: "Manager Email",
            placeholder: "example@email.com",
            touched: false,
            valid: false,
            value: "",
            filter: filters.combine_filters(filters.lowercase_string, filters.remove_spaces),
            validator: validators.combineAnd(validators.requiredText, validators.isEmail)
        },
        relation: {
            type: InputTypes.Text,
            label: "Relation to Student",
            touched: false,
            valid: false,
            value: "",
            validator: validators.requiredText
        }
    });
    const [invite_manager_form_valid, set_invite_manager_form_valid] = useState<boolean>(false);

    const [student_managers, set_student_managers] = useState<StudentManagers.StudentManagerEntityDTO[]>([]);

    const [loading, set_loading] = useState<boolean>(true);

    const set_student_data = async (student_data: Students.StudentEntityDTO) => {
        set_student(student_data);

        const updated_form: IEditStudentForm = {
            first_name: {
                ...edit_student_form.first_name,
                value: student_data.firstName
            },
            last_name: {
                ...edit_student_form.last_name,
                value: student_data.lastName
            }
        };

        const {form, form_valid} = forms.filter_and_validate_form(updated_form);
        set_edit_student_form(form as IEditStudentForm);
        set_edit_student_form_valid(form_valid);
    };

    const load_page_data = async (student_id: number) => {

        set_loading(true);
        set_student(null);

        try {

            const student_page = await TutorAPI.studentPage(student_id);

            await set_student_data(student_page.student);
            set_manager_invites(student_page.unacceptedInvites);
            set_student_managers(student_page.managers);

        } catch (e) {
            props.set_error('Unable to load student.', e);
            props.history.goBack();
        }

        set_loading(false);

    };

    useEffect(() => {
        const student_id = Number(params.student_id);
        if (isNaN(student_id)) {
            props.set_error('Invalid student id.', null);
            props.history.push('/tutor/students');
            return;
        }
        load_page_data(student_id);
        // eslint-disable-next-line
    }, [])

    if (loading || !student) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const edit_form_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (loading || !student) return;

        const {form, form_valid} = forms.filter_and_validate_form(edit_student_form);
        const final_form = form as IEditStudentForm;
        if (!form_valid) {
            set_edit_student_form(final_form);
            set_edit_student_form_valid(form_valid);
            return;
        }

        set_loading(true);

        try {
            const updatedStudent = await TutorAPI.updateStudent({
                studentId: student.studentId,
                firstName: final_form.first_name.value,
                lastName: final_form.last_name.value
            });
            await set_student_data(updatedStudent);
        } catch (e) {
            props.set_error('Unable to update student.', e);
        }

        set_loading(false);

    };

    const edit_form_change = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, edit_student_form);
        set_edit_student_form(form as IEditStudentForm);
        set_edit_student_form_valid(form_valid);
    };

    const render_form_element = (key: string, index: number) => {
        const element = edit_student_form[key];
        return (
            <Col key={index}>
                <InputElement element={element} changed={(event: any) => edit_form_change(key, event)}/>
            </Col>
        );
    };

    const render_edit_student_form = () => (
        <Form onSubmit={edit_form_submit}>
            <TitleValuePairRow
                title={'Date Registered'}
                value={moment(student.registerDate).format('MM/DD/YYYY hh:mm A')}/>
            <Row className={'mb-2 mt-2'}>
                {Object.keys(edit_student_form).map(render_form_element)}
            </Row>
            <Row>
                <Col>
                    <Button
                        type={'submit'}
                        variant={'success'}
                        disabled={loading || !edit_student_form_valid || !student}
                        className={'me-2'}>Save</Button>
                    <Button
                        type={'button'}
                        variant={'secondary'}
                        onClick={() => props.history.goBack()}>Cancel</Button>
                </Col>
            </Row>
        </Form>
    );

    const render_pending_invitations = () => {
        const render_invitation_row = (invitation: StudentManagers.StudentManagerInviteEntityDTO, index: number) => (
            <tr key={index}>
                <td>{moment(invitation.inviteDate).format("MM/DD/YYYY hh:mm A")}</td>
                <td><a href={`mailto:${invitation.managerEmail}`}>{invitation.managerEmail}</a></td>
                <td>{invitation.relation}</td>
            </tr>
        );
        return (
            <Table responsive striped>
                <thead>
                <tr>
                    <th>Invitation Date and Time</th>
                    <th>Manager Email</th>
                    <th>Relation to Student</th>
                </tr>
                </thead>
                <tbody>
                {manager_invites.map(render_invitation_row)}
                </tbody>
            </Table>
        );
    };

    const invite_manager_form_change = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, invite_manager_form);
        set_invite_manager_form(form as IInviteManagerForm);
        set_invite_manager_form_valid(form_valid);
    };

    const invite_manager_form_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (loading || !student) return;
        const {form, form_valid} = forms.filter_and_validate_form(invite_manager_form);
        const final_form = form as IInviteManagerForm;
        if (!form_valid) {
            set_invite_manager_form(final_form);
            set_invite_manager_form_valid(form_valid);
            return;
        }

        try {
            set_loading(true);

            const newInvite = await TutorAPI.inviteStudentManager({
                studentId: student.studentId,
                managerEmail: final_form.manager_email.value,
                relation: final_form.relation.value
            });

            const updated_invites = [...manager_invites, newInvite]
                .sort((a, b) => moment(a.inviteDate).diff(moment(b.inviteDate)));

            set_manager_invites(updated_invites);

            const updated_form: IInviteManagerForm = {
                manager_email: {
                    ...final_form.manager_email,
                    value: "",
                    touched: false,
                    valid: false
                },
                relation: {
                    ...final_form.relation,
                    value: "",
                    touched: false,
                    valid: false
                }
            };

            set_invite_manager_form(updated_form);
            set_invite_manager_form_valid(false);

        } catch (e) {
            props.set_error('Unable to invite manager.', e);
        }

        set_loading(false);

    };

    const render_invite_manager_form = () => {
        const render_form_element = (key: string, index: number) => {
            const element = invite_manager_form[key];
            return (
                <Col key={index}>
                    <InputElement element={element} changed={(event: any) => invite_manager_form_change(key, event)}/>
                </Col>
            );
        };
        return (
            <Form onSubmit={invite_manager_form_submit}>
                <Row className={'mb-2'}>
                    {Object.keys(invite_manager_form).map(render_form_element)}
                </Row>
                <Row>
                    <Col>
                        <Button
                            type={'submit'}
                            variant={'success'}
                            disabled={loading || !student || !invite_manager_form_valid}>Save</Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    const render_student_manager = (manager: StudentManagers.StudentManagerEntityDTO, index: number) => (
        <Col key={index} xs={12} md={6}>
            <UserCard user={manager.user} title={manager.relation}/>
        </Col>
    );

    return (
        <Col>
            <Helmet>
                <title>Student Details | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Student Details</h1>
            {render_edit_student_form()}
            <h2>Student Managers</h2>
            <Row>
                {student_managers.map(render_student_manager)}
            </Row>
            <h3>Invite Manager</h3>
            {render_invite_manager_form()}
            {manager_invites.length > 0 ? (<>
                <h3>Pending Invitations</h3>
                {render_pending_invitations()}
            </>) : null}
        </Col>
    )
};

export default connector(TutorStudentPage);
