import React from "react";
import {Form} from "react-bootstrap";

import PasswordInputType from "../../../data/ui/input/PasswordInputType";

interface PasswordElementProps {
    element: PasswordInputType;
    changed (event: any) : void;
}

const PasswordElement = (props : PasswordElementProps) => (
    <Form.Control
        type={"password"}
        placeholder={props.element.label || ''}
        value={props.element.value}
        onChange={props.changed}
        isInvalid={!props.element.valid && props.element.touched}
        isValid={props.element.valid}
    />
);

export default PasswordElement;
