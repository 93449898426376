import {Func} from "../data/Func";

export const integer_number: Func<[string], string> = (input: string) => isNaN(Number(input)) ?
    input :
    Math.floor(Number(input)).toString(10);

export const lowercase_string: Func<[string], string> = (input: string) => input.toLowerCase();
export const remove_spaces: Func<[string], string> = (input: string) => input.replace(/\s/g, '');

export const combine_filters = <InType>(...filters: Func<[InType], InType>[]):
    Func<[InType], InType> => {
    return (input: InType) => {
        let out: InType = input;
        for (let i = 0; i < filters.length; i++) {
            out = filters[i](out);
        }
        return out;
    }
};

