import React from "react";
import {Typeahead} from "react-bootstrap-typeahead";
import {Option} from "react-bootstrap-typeahead/types/types";
import SingleTypeAheadInputType from "../../../data/ui/input/SingleTypeAheadInputType";

interface SingleTypeAheadInputElementProps {
    element: SingleTypeAheadInputType;

    onChange(event: any): void;
}

const SingleTypeAheadInputElement = (props: SingleTypeAheadInputElementProps) =>
    // @ts-ignore
    <Typeahead
        options={props.element.options}
        id={props.element.id}
        selected={props.element.value}
        allowNew={props.element.allow_new}
        isValid={props.element.valid}
        isInvalid={!props.element.touched && !props.element.valid}
        onChange={(selected: Option[]) => props.onChange({target: {value: [...selected]}})}
    />;

export default SingleTypeAheadInputElement;
