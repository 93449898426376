import React from "react";
import {Form} from "react-bootstrap";
import {FileInputType} from "../../../data/ui/input/FileInputType";

type Props = {
    element: FileInputType;
    changed(event: any): void;
};

const FileElement = ({element, changed}: Props) => {
    const on_change = (event: any) => {
        changed({target: {value: event.target.files.length >= 0 ? event.target.files[0] : null}});
    };
    return (
        <Form.Control
            type={'file'}
            onChange={on_change}
            accept={element.accept}
            isInvalid={!element.valid && element.touched}
            isValid={element.valid}/>
    );
};

export default FileElement;
