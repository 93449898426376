import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faSignInAlt, faUserPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";

import InputType, {InputTypes} from "../../data/ui/input/InputType";
import TextInputType from "../../data/ui/input/TextInputType";
import PasswordInputType from "../../data/ui/input/PasswordInputType";
import StateType from "../../store/StateType";
import * as validators from '../../util/validators';
import * as actions from '../../store/actions';
import * as forms from '../../util/forms';
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import InputElement from "../../components/InputElement/InputElement";

const map_state_to_props = (state: StateType) => ({
    is_logging_in: state.account.is_logging_in
});

const map_dispatch_to_props = (dispatch: any) => ({
    clear_error: () => dispatch(actions.clear_error()),
    login: (username: string, password: string) => dispatch(actions.login(username, password))
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Login = (props: PropsFromRedux) => {
    const {
        clear_error,
        is_logging_in,
        login,
    } = props;
    const [form, set_form] = useState<Record<string, InputType>>({
        username: {
            type: InputTypes.Text,
            value: '',
            touched: false,
            valid: false,
            label: 'Username',
            filter: input => input.trim().toLowerCase(),
            validator: validators.requiredText
        } as TextInputType,
        password: {
            type: InputTypes.Password,
            value: '',
            touched: false,
            valid: false,
            label: 'Password',
            filter: input => input.trim(),
            validator: validators.requiredText
        } as PasswordInputType
    });

    const [form_valid, set_form_valid] = useState<boolean>(false);

    useEffect(() => {
        clear_error();
        // eslint-disable-next-line
    }, []);

    const on_form_update = (event: any, key: string) => {
        const updated_form = forms.update_form(event, key, form);
        set_form(updated_form.form);
        set_form_valid(updated_form.form_valid);
    };

    const render_form = () => {
        return Object.keys(form).map((form_key) => {
            const form_element = form[form_key];
            return (
                <InputElement
                    key={form_key}
                    element={form_element}
                    changed={(event) => on_form_update(event, form_key)}/>
            );
        });
    };

    const submit_form = (event:any) => {
        event.preventDefault();
        if (!form_valid) return;

        const filtered_form = forms.filter_and_validate_form(form);

        if (!filtered_form.form_valid) return;

        const username = filtered_form.form.username as TextInputType;
        const password = filtered_form.form.password as PasswordInputType;

        login(username.value, password.value);

    };

    return (
        <Modal show={true} size={'lg'}>
            <Modal.Header>
                <h2>Login</h2>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submit_form}>
                    {render_form()}
                    <Form.Group className={"mt-3"}>
                        <Button
                            variant={'success'}
                            disabled={is_logging_in || !form_valid}
                            type={"submit"}>
                            <FontAwesomeIcon icon={faSignInAlt} fixedWidth /> Login
                        </Button>
                    </Form.Group>
                    <Form.Group className={"mt-3"}>
                        <Button
                            variant={'primary'}
                            href={'https://www.tinycountrygames.com/tcgaccounts/signup/'}
                            type={'button'}>
                            <FontAwesomeIcon icon={faUserPlus} fixedWidth /> Create an Account
                        </Button>
                    </Form.Group>
                    <Form.Group className={"mt-3 mb-3"}>
                        <Button
                            variant={'warning'}
                            href={'https://www.tinycountrygames.com/tcgaccounts/reset'}
                            type={'button'}>
                            <FontAwesomeIcon icon={faQuestionCircle} fixedWidth /> Reset Password
                        </Button>
                    </Form.Group>
                </Form>
                <ErrorAlert/>
                {is_logging_in ? (
                    <Alert
                        variant={'warning'}>
                        <FontAwesomeIcon icon={faSpinner} spin/> Logging in...
                    </Alert>
                ) : null}
            </Modal.Body>
        </Modal>
    );
};

export default connector(Login);
