import React, {FormEvent, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Button, Col, Form, Row} from "react-bootstrap";

import StateType from "../../store/StateType";
import * as actions from '../../store/actions';
import * as forms from '../../util/forms';
import * as validators from '../../util/validators';
import InputType, {InputTypes} from "../../data/ui/input/InputType";
import TextInputType from "../../data/ui/input/TextInputType";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import InputElement from "../../components/InputElement/InputElement";
import {Helmet} from "react-helmet";
import TutorAPI from "../../api/TutorAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ICreateStudentProps extends PropsFromRedux, RouteComponentProps {
}

interface ICreateStudentForm extends Record<string, InputType> {
    firstName: TextInputType;
    lastName: TextInputType;
}

const CreateStudent = (props: ICreateStudentProps) => {

    const [create_student_form, set_create_student_form] = useState<ICreateStudentForm>({
        firstName: {
            type: InputTypes.Text,
            valid: false,
            touched: false,
            label: 'First Name',
            placeholder: 'First Name',
            value: '',
            validator: validators.requiredText
        },
        lastName: {
            type: InputTypes.Text,
            valid: false,
            touched: false,
            label: 'Last Name',
            placeholder: 'Last Name',
            value: '',
            validator: validators.requiredText
        }

    });

    const [create_student_form_valid, set_create_student_form_valid] = useState<boolean>(false);

    const [form_submitting, set_form_submitting] = useState<boolean>(false);

    const on_create_student_form_change = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, create_student_form);
        set_create_student_form(form as ICreateStudentForm);
        set_create_student_form_valid(form_valid);
    };

    const on_create_student_form_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (form_submitting) return;
        const {form, form_valid} = forms.filter_and_validate_form(create_student_form);
        const final_form = form as ICreateStudentForm;
        if (!form_valid) {
            set_create_student_form(final_form);
            set_create_student_form_valid(form_valid);
            return;
        }
        try {
            set_form_submitting(true);

            const student = await TutorAPI.registerStudent({
                firstName: final_form.firstName.value.trim(),
                lastName: final_form.lastName.value.trim()
            });

            props.history.push(`/tutor/students/${student.studentId}`);

        } catch (e) {
            props.set_error('Unable to register student.', e);
        }
        set_form_submitting(false);
    }

    if (form_submitting) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const render_form_element = (key: string, index: number) => {
        const element = create_student_form[key];
        return (
            <Col key={index} xs={12} md={6}>
                <InputElement element={element} changed={(event: any) => on_create_student_form_change(key, event)}/>
            </Col>
        );
    };

    return (
        <Col>
            <Helmet>
                <title>Create Student | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Create Student</h1>
            <Form onSubmit={on_create_student_form_submit}>
                <Row>
                    {Object.keys(create_student_form).map(render_form_element)}
                </Row>
                <Button
                    type={'submit'}
                    variant={'success'}
                    disabled={!create_student_form_valid || form_submitting}
                    className={'mt-2'}>Create Student</Button>
            </Form>
        </Col>
    );
};

export default connector(CreateStudent);
