import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Container, Navbar} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import StateType from "../../store/StateType";

const map_state_to_props = (state: StateType) => ({
    user_data: state.account.user_data
});

const connector = connect(map_state_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>

const TopBar = (props: PropsFromRedux) => (
    <Navbar bg="dark" variant={'dark'} expand="lg">
        <Container fluid>
            <LinkContainer to={'/'}>
                <Navbar.Brand>Tiny Country Tutoring</Navbar.Brand>
            </LinkContainer>
            {props.user_data ? (
                <Navbar.Text className={'d-none d-md-block'}>
                    Signed in as {props.user_data.username}
                </Navbar.Text>
            ) : null}
        </Container>
    </Navbar>
);

export default connector(TopBar);
