import React, {FormEvent, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Button, Col, Form} from "react-bootstrap";
import moment from "moment";

import StateType from "../../store/StateType";
import * as actions from '../../store/actions';
import InputType, {InputTypes} from "../../data/ui/input/InputType";
import TextInputType from "../../data/ui/input/TextInputType";
import * as validators from '../../util/validators';
import {session_list_with_session_removed} from "../../data/Session";
import * as forms from '../../util/forms';
import InputElement from "../../components/InputElement/InputElement";
import TutorPaymentSessionsTable from "../../components/TutorPaymentSessionsTable/TutorPaymentSessionsTable";
import {Helmet} from "react-helmet";
import TutorAPI from "../../api/TutorAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ICreatePaymentProps extends PropsFromRedux, RouteComponentProps {
}

interface ICreateFormType extends Record<string, InputType> {
    method: TextInputType;
    note: TextInputType;
}

const CreatePayment = (props: ICreatePaymentProps) => {

    const [create_form, set_create_form] = useState<ICreateFormType>({
        method: {
            type: InputTypes.Text,
            label: 'Payment Method',
            placeholder: 'Method',
            value: '',
            touched: false,
            valid: false,
            validator: validators.requiredText
        },
        note: {
            type: InputTypes.Text,
            label: 'Note',
            placeholder: 'Note',
            value: '',
            touched: true,
            valid: true,
            validator: (_input: string) => true
        }
    });
    const [create_form_valid, set_create_form_valid] = useState<boolean>(false);
    const [sessions_without_payments, set_sessions_without_payments] = useState<Sessions.TutoringSessionEntityDTO[]>([]);
    const [sessions_in_payment, set_sessions_in_payment] = useState<Sessions.TutoringSessionEntityDTO[]>([]);
    const [loading, set_loading] = useState<boolean>(true);

    const load_sessions_without_payments = async () => {
        try {
            const sessions = await TutorAPI.sessionsWithoutPayment();
            sessions.sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))
            set_sessions_without_payments(sessions);
        } catch (e) {
            props.set_error('Unable to load sessions.', e);
        }
        set_loading(false);
    };

    useEffect(() => {
        load_sessions_without_payments();
        // eslint-disable-next-line
    }, []);

    const submit_form = async (event: FormEvent) => {
        event.preventDefault();
        if (loading) return;
        set_loading(true);
        try {
            const {form, form_valid} = forms.filter_and_validate_form(create_form);
            const final_form = form as ICreateFormType;
            if (!form_valid) {
                set_create_form_valid(form_valid);
                set_create_form(final_form);
                return;
            }
            const new_payment = await TutorAPI.createPayment({
                method: final_form.method.value,
                note: final_form.note.value,
                sessionIds: [...sessions_in_payment].map((session) => session.sessionId)
            });
            props.history.push(`/tutor/payments/${new_payment.paymentId}`);
        } catch (e) {
            props.set_error('Unable to create payment', e);
        }
        set_loading(false);
    };

    const on_form_change = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, create_form);
        set_create_form(form as ICreateFormType);
        set_create_form_valid(form_valid);
    };

    const render_form_elements = () => {
        const render_form_element = (form_element_key: string, index: number) => {
            return (
                <InputElement
                    key={index}
                    element={create_form[form_element_key]}
                    changed={(event: any) => on_form_change(form_element_key, event)}/>
            );
        }
        return Object.keys(create_form).map(render_form_element);
    };

    const add_session_to_payment = (session: Sessions.TutoringSessionEntityDTO) => {
        const without_payments = session_list_with_session_removed(sessions_without_payments, session);
        const in_payment = [...sessions_in_payment, session];
        in_payment.sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));
        set_sessions_in_payment(in_payment);
        set_sessions_without_payments(without_payments);
    };

    const remove_session_from_payment = (session: Sessions.TutoringSessionEntityDTO) => {
        const without_payments = [...sessions_without_payments, session];
        without_payments.sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));
        const in_payment = session_list_with_session_removed(sessions_in_payment, session);
        set_sessions_in_payment(in_payment);
        set_sessions_without_payments(without_payments);
    };

    return (
        <Col>
            <Helmet>
                <title>Create Payment | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Create Payment</h1>
            <Form onSubmit={submit_form}>
                {render_form_elements()}
                <h2 className={'mt-2'}>Sessions in Payment</h2>
                <TutorPaymentSessionsTable
                    sessions={sessions_in_payment}
                    add_to_payment={false}
                    show_total={true}
                    add_session_to_payment={add_session_to_payment}
                    remove_session_from_payment={remove_session_from_payment}/>
                <h2>Sessions without Payment</h2>
                <TutorPaymentSessionsTable
                    sessions={sessions_without_payments}
                    add_to_payment={true}
                    show_total={false}
                    add_session_to_payment={add_session_to_payment}
                    remove_session_from_payment={add_session_to_payment}/>
                <Button
                    type={'submit'}
                    variant={'success'}
                    className={'mt-3'}
                    disabled={loading || !create_form_valid}>
                    Create Payment
                </Button>
            </Form>
        </Col>
    );
};

export default connector(CreatePayment);
