import {AccountActionTypes} from './acctionTypes';
import * as common from './common';
import WebAPI from "../../api/WebAPI";
import AccountAPI from "../../api/AccountAPI";
import moment from 'moment';
import TutorAPI from "../../api/TutorAPI";
import {AxiosError} from "axios";


const set_logging_in = (is_logging_in: boolean) => ({
    type: AccountActionTypes.SET_LOGGING_IN,
    is_logging_in: is_logging_in
});

const set_logged_in = (logged_in: boolean) => ({
    type: AccountActionTypes.SET_LOGGED_IN,
    is_logged_in: logged_in
});

const set_is_tutor = (is_registered_tutor: boolean) => ({
    type: AccountActionTypes.SET_IS_TUTOR,
    is_registered_tutor: is_registered_tutor
});

const set_tutor_data = (tutor_profile: Tutors.TutorEntityDTO | null) => ({
    type: AccountActionTypes.SET_TUTOR_DATA,
    tutor_profile: tutor_profile
});

const set_user_data = (user_data: Users.UserDTO | null) => ({
    type: AccountActionTypes.SET_USER_DATA,
    user_data: user_data
});

const load_tutor_profile = async () => {
    return await TutorAPI.me();
}

const load_user_data = async (dispatch: any, _user?: Users.UserDTO) => {
    try {
        const is_tutor = await TutorAPI.isTutor();
        dispatch(set_is_tutor(is_tutor));
        if (is_tutor) {
            const tutor_profile = await load_tutor_profile();
            dispatch(set_tutor_data(tutor_profile));
        } else {
            const user = _user ?? await AccountAPI.me();
            dispatch(set_tutor_data(null));
            dispatch(set_user_data(user));
        }
        dispatch(set_logged_in(true));
    } catch (e: any) {
        dispatch(set_is_tutor(false));
        dispatch(set_tutor_data(null));
        dispatch(set_user_data(null));
        if (!(e as AxiosError)?.isAxiosError || (e as AxiosError).response?.status !== 403) {
            dispatch(common.set_error_from_response('Unable to load account data.', e));
        }
    }
}

export const initial_load_account_data = () => {
    return async (dispatch: any) => {
        dispatch(set_logged_in(false));
        await AccountAPI.requestCsrfToken();
        await load_user_data(dispatch);

        dispatch(common.set_loading(false));
    };
};

export const login = (username: string, password: string) => {
    return async (dispatch: any) => {
        dispatch(common.clear_error());
        dispatch(set_logging_in(true));
        dispatch(set_tutor_data(null));
        dispatch(set_is_tutor(false));
        try {
            const user = await AccountAPI.login({username, password});
            await load_user_data(dispatch, user);
        } catch (e) {
            dispatch(common.set_error_message(WebAPI.errorMessage(e, 'Unable to login.')));
        }
        dispatch(set_logging_in(false));
    };
};

export const logout = () => {
    return async (dispatch: any) => {
        dispatch(setIsExpiredSession(false));
        try {
            await AccountAPI.signOut();
        } catch (e) {
            console.error(e);
        }
        dispatch(set_logged_in(false));
        dispatch(set_is_tutor(false));
        dispatch(set_user_data(null));
        dispatch(set_tutor_data(null));
    };
};

export const set_tutor_profile = (tutor: Tutors.TutorEntityDTO) => {
    return (dispatch: any) => {
        dispatch(set_tutor_data(tutor));
        dispatch(set_is_tutor(true));
    }
};

export const set_csrf_token = (csrf_token: string) => ({
    type: AccountActionTypes.SET_CSRF_TOKEN,
    csrf_token: csrf_token
});

export const setIsExpiredSession = (is_expired_session: boolean) => ({
    type: AccountActionTypes.SET_IS_EXPIRED_SESSION,
    is_expired_session: is_expired_session
});
