declare global {
    type PaginatedList<T> = {
        page: number;
        pageSize: number;
        hasMore: boolean;
        items: T[];
    };
    type ListPageRequest = {
        page: number;
        pageSize: number;
    };
}

export namespace Pagination {
    export const listPageRequestToParams = (request: ListPageRequest) => new URLSearchParams({
        page: `${request.page}`,
        pageSize: `${request.pageSize}`,
    });
}
