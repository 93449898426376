import React from "react";
import {Col, Row} from "react-bootstrap";
import TitleValuePair from "../../data/ui/TitleValuePair";

const TitleValuePairRow = (props: TitleValuePair) => (
    <Row className={'mb-2'}>
        <Col xs={12} md={2} className={'fw-bold'}>{props.title}</Col>
        <Col>{props.value}</Col>
    </Row>
);

export default TitleValuePairRow;
