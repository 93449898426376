import React, {useEffect, useState} from "react";
import {Accordion, Button, Ratio} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";

import {AttachmentTypes} from "../../data/AttachmentType";

interface ISessionAttachmentItemProps {
    attachment: Sessions.SessionAttachmentEntity;
    eventKey: string;
}

const SessionAttachmentItem = (props: ISessionAttachmentItemProps) => {

    const [pdfContentDataUrl, setPdfContentDataUrl] = useState<string | null>(null);

    useEffect(() => {
        if (props.attachment.attachmentType.attachmentTypeId === AttachmentTypes.PDF_LINK) {
            fetch(props.attachment.attachmentLink)
                .then((response) => response.blob())
                .then((blob) => {
                    return new Promise<string>((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result + '');
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    });
                })
                .then((dataUrl) => {
                    setPdfContentDataUrl(dataUrl.replace('application/octet-stream', 'application/pdf'));
                });
        }
    }, [props.attachment.attachmentLink, props.attachment.attachmentType]);

    let header = (<Accordion.Header>Other Link</Accordion.Header>);
    let body = (
        <Accordion.Body>
            <Button href={props.attachment.attachmentLink}>
                <FontAwesomeIcon icon={faExternalLinkAlt}/> Go to attachment
            </Button>
        </Accordion.Body>
    );
    switch (props.attachment.attachmentType.attachmentTypeId) {
        case AttachmentTypes.YOUTUBE_UPLOAD:
            header = (<Accordion.Header>Youtube Video</Accordion.Header>);
            body = (
                <Accordion.Body>
                    <Ratio aspectRatio={'16x9'}>
                        <iframe
                            title={`attachment_yt_${props.attachment.attachmentId}`}
                            src={props.attachment.attachmentLink}/>
                    </Ratio>
                </Accordion.Body>
            );
            break;
        case AttachmentTypes.PDF_LINK:
            header = (<Accordion.Header>PDF Link</Accordion.Header>);
            body = (
                <Accordion.Body>
                    <Ratio aspectRatio={'16x9'}>
                        {pdfContentDataUrl ? (
                            <iframe
                                title={`attachment_pdf_${props.attachment.attachmentId}`}
                                src={pdfContentDataUrl}/>
                        ) : (<div>Loading...</div>)}
                    </Ratio>
                </Accordion.Body>
            );
            break;
    }
    return (
        <Accordion.Item eventKey={props.eventKey}>
            {header}
            {body}
        </Accordion.Item>
    );
};

export default SessionAttachmentItem;
