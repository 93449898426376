import React from "react";
import {Form} from "react-bootstrap";
import SelectInputType, {SelectOption} from "../../../data/ui/input/SelectInputType";

interface SelectElementProps {
    element: SelectInputType<any>;
    change (event: any) : void;
}

const SelectElement = (props: SelectElementProps) => {
    const render_option = (option: SelectOption<any>, index : number) => (
        <option key={index} value={props.element.value_to_string(option.value)}>{option.name}</option>
    );
    return (
        <Form.Select
            value={props.element.value_to_string(props.element.value)}
            onChange={props.change}>
            {props.element.options.map(render_option)}
        </Form.Select>
    )
};

export default SelectElement;
