import React, {FormEvent, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import {RouteComponentProps, useParams} from "react-router";
import * as actions from '../../../store/actions';
import * as forms from '../../../util/forms';
import * as validators from '../../../util/validators';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import {session_list_with_session_removed} from "../../../data/Session";
import PaymentAuditTrailTable from "../../../components/PaymentAuditTrailTable/PaymentAuditTrailTable";
import TitleValuePairRow from "../../../components/TitleValuePairRow/TitleValuePairRow";
import InputType, {InputTypes} from "../../../data/ui/input/InputType";
import SelectInputType from "../../../data/ui/input/SelectInputType";
import TextInputType from "../../../data/ui/input/TextInputType";
import InputElement from "../../../components/InputElement/InputElement";
import TutorPaymentSessionsTable from "../../../components/TutorPaymentSessionsTable/TutorPaymentSessionsTable";
import {Helmet} from "react-helmet";
import TutorAPI from "../../../api/TutorAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ITutorPaymentPageProps extends PropsFromRedux, RouteComponentProps {

}

const TutorPaymentPage = (props: ITutorPaymentPageProps) => {
    const params = useParams<{ payment_id?: string | undefined }>();
    const [loading, set_loading] = useState<boolean>(true);
    const [payment, set_payment] = useState<Payments.TutoringPaymentEntityDTO | null>(null);
    const [sessions_without_payment, set_sessions_without_payment] = useState<Sessions.TutoringSessionEntityDTO[]>([]);

    const [update_form, set_update_form] = useState<Record<string, InputType>>({
        payment_method: {
            type: InputTypes.Text,
            value: '',
            valid: false,
            touched: false,
            validator: validators.requiredText
        } as TextInputType,
        payment_status: {
            type: InputTypes.Select,
            valid: true,
            value: true,
            touched: true,
            options: [
                {value: true, name: 'Paid'},
                {value: false, name: 'Unpaid'}
            ],
            value_to_string: (value: boolean): string => `${value}`,
            filter: (value: string): boolean => value.trim().toLowerCase() === 'true'
        } as SelectInputType<boolean>,
        note: {
            type: InputTypes.Text,
            value: '',
            valid: false,
            touched: false
        } as TextInputType
    });
    const [update_form_valid, set_update_form_valid] = useState<boolean>(false);

    const load_sessions_without_payment = async () => {
        const sessions = await TutorAPI.sessionsWithoutPayment();
        set_sessions_without_payment(sessions);
    };

    const set_payment_data = async (payment: Payments.TutoringPaymentEntityDTO) => {
        set_payment(payment);
        await load_sessions_without_payment();
        const updated_update_form = {
            payment_method: {
                ...update_form.payment_method,
                value: payment.method,
                valid: true
            },
            payment_status: {
                ...update_form.payment_status,
                value: payment.paid
            },
            note: {
                ...update_form.note,
                value: payment.note,
                valid: true
            }
        };
        set_update_form(updated_update_form);
        set_update_form_valid(true);
    };

    const load_payment = async (payment_id: number) => {
        set_loading(true);
        try {
            const payment = await TutorAPI.loadPayment(payment_id);
            await set_payment_data(payment);
            set_loading(false);
        } catch (e) {
            props.set_error('Unable to load payment', e);
            props.history.push('/tutor/payments');
        }
    };

    useEffect(() => {
        const payment_id = Number(params.payment_id);
        if (isNaN(payment_id)) {
            props.set_error('Invalid payment id.', null);
            props.history.push('/tutor/payments');
            return;
        }
        load_payment(payment_id);
        // eslint-disable-next-line
    }, []);

    const on_update_form_change = (event: any, key: string) => {
        const {form, form_valid} = forms.update_form(event, key, update_form);
        set_update_form(form);
        set_update_form_valid(form_valid);
    };

    if (loading || !payment) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const add_session_to_payment = (session: Sessions.TutoringSessionEntityDTO) => {
        const updated_payment: Payments.TutoringPaymentEntityDTO = {...payment};
        const updated_payment_sessions = [...updated_payment.sessions, session];
        const updated_sessions_without_payment = session_list_with_session_removed(sessions_without_payment, session);
        updated_payment.sessions = updated_payment_sessions;
        set_payment(updated_payment);
        set_sessions_without_payment(updated_sessions_without_payment);
    };

    const remove_session_from_payment = (session: Sessions.TutoringSessionEntityDTO) => {
        const updated_payment: Payments.TutoringPaymentEntityDTO = {...payment};
        const updated_payment_sessions = session_list_with_session_removed(payment.sessions, session);
        const updated_sessions_without_payment = [...sessions_without_payment, session];
        updated_payment.sessions = updated_payment_sessions;
        set_payment(updated_payment);
        set_sessions_without_payment(updated_sessions_without_payment);
    };

    const submit_update_form = async (event: FormEvent) => {
        event.preventDefault();
        if (!update_form_valid || loading || !payment) return;
        set_loading(true);
        try {
            const payment_id = payment.paymentId;
            const session_ids = payment.sessions.map(session => session.sessionId);
            const updatedPayment = await TutorAPI.updatePayment({
                paymentId: payment_id,
                method: update_form.payment_method.value,
                note: update_form.note.value,
                paid: update_form.payment_status.value,
                sessionIds: session_ids,
            });
            await set_payment_data(updatedPayment);
        } catch (e) {
            props.set_error('Unable to update payment.', e);
        }
        set_loading(false);
    };

    const render_update_form = () => {
        const render_update_form_element = (key: string, index: number) => (
            <Row className={'mb-2'} key={index}>
                <Col xs={12} md={2} className={'fw-bold'}>
                    {
                        key.replace(/_/g, ' ')
                            .replace(/(^.|\s.)/g, (str: string) => str.toUpperCase())
                    }
                </Col>
                <Col>
                    <InputElement
                        element={update_form[key]}
                        changed={(event: any) => on_update_form_change(event, key)}/>
                </Col>
            </Row>
        );
        return (
            <Form onSubmit={submit_update_form}>
                {Object.keys(update_form).map(render_update_form_element)}
            </Form>
        );
    };

    return (
        <Col>
            <Helmet>
                <title>Payment | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Payment</h1>
            <TitleValuePairRow title={'Total Sessions'} value={payment.sessions.length.toString()}/>
            {render_update_form()}
            <h2>Sessions</h2>
            <h3>Session in Payment</h3>
            <TutorPaymentSessionsTable
                sessions={payment.sessions}
                add_to_payment={false}
                show_total={true}
                add_session_to_payment={add_session_to_payment}
                remove_session_from_payment={remove_session_from_payment}/>
            <h3>Sessions Without Payment</h3>
            <TutorPaymentSessionsTable
                sessions={sessions_without_payment}
                add_to_payment={true}
                show_total={false}
                add_session_to_payment={add_session_to_payment}
                remove_session_from_payment={remove_session_from_payment}/>
            <Row className={'mb-3'}>
                <Col>
                    <Button
                        type={'button'}
                        variant='success'
                        className={'me-2'}
                        disabled={loading || !payment || !update_form_valid}
                        onClick={submit_update_form}>Save Changes</Button>
                    <Button
                        type={'button'}
                        variant='secondary'
                        onClick={() => props.history.goBack()}>Cancel</Button>
                </Col>
            </Row>
            <h2>Audit Trail</h2>
            <PaymentAuditTrailTable audit_trail={payment.auditTrail}/>
        </Col>
    );
};

export default connector(TutorPaymentPage);
