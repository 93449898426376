import {Table} from "react-bootstrap";
import React from "react";
import moment from "moment";

type TutorStudentTableProps = {
    students: Students.StudentEntityDTO[];
    onStudentClick: (student: Students.StudentEntityDTO) => void;
};

type TutorStudentRowProps = TutorStudentTableProps & {
    student: Students.StudentEntityDTO;
};

const TutorStudentRow = (props: TutorStudentRowProps) => {
    const {
        student,
        onStudentClick,
    } = props;

    const {
        firstName,
        lastName,
        registerDate,
    } = student;

    return (
        <tr
            className={'clickable-row'}
            onClick={() => onStudentClick(student)}
        >
            <td>{firstName} {lastName}</td>
            <td>{moment(registerDate).format('MM/DD/YYYY')}</td>
        </tr>
    );
}

const TutorStudentTable = (props: TutorStudentTableProps) => {

    const {
        students,
    } = props;

    return (
        <Table responsive striped>
            <thead>
            <tr>
                <th>Name</th>
                <th>Register Date</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {students.map((student, index) => (
                <TutorStudentRow
                    key={index}
                    student={student}
                    {...props}
                />
            ))}
            </tbody>
        </Table>
    );
};

export default TutorStudentTable;
