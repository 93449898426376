import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps, useParams} from "react-router";
import {Col, Table} from "react-bootstrap";
import moment from "moment";

import StateType from "../../../store/StateType";
import * as actions from "../../../store/actions";
import {calculate_session_derived_fields} from "../../../data/Session";
import {calculate_payment_derived_fields} from "../../../data/Payment";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import PaymentBasicDetails from "../../../components/PaymentBasicDetails/PaymentBasicDetails";
import PaymentAuditTrailTable from "../../../components/PaymentAuditTrailTable/PaymentAuditTrailTable";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../../api/ManagerAPI";

const mapDispatchToProps = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, mapDispatchToProps);

interface IManagerPaymentPage extends ConnectedProps<typeof connector>, RouteComponentProps {
}

const ManagerPaymentPage = (props: IManagerPaymentPage) => {

    const params = useParams<{ payment_id?: string | undefined }>();

    const [payment, set_payment] = useState<Payments.TutoringPaymentEntityDTO | null>(null);
    const [loading, set_loading] = useState<boolean>(true);

    const load_payment = async (payment_id: number) => {
        const payment = await ManagerAPI.loadPayment(payment_id);
        set_payment(payment);
    };

    useEffect(() => {
        const payment_id = Number(params.payment_id);
        if (isNaN(payment_id)) {
            props.set_error('Invalid payment id.', null);
            props.history.push('/manager/payments');
            return;
        }
        load_payment(payment_id)
            .then(() => set_loading(false))
            .catch(e => {
                props.set_error('Unable to load payment', e);
                props.history.push('/manager/payments')
            });
        // eslint-disable-next-line
    }, []);

    if (loading || !payment) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const render_sessions_table = () => {
        const {total_duration_hours, total_amount_owed_cents} = calculate_payment_derived_fields(payment);
        const render_session_row = (session: Sessions.TutoringSessionEntityDTO, index: number) => {
            const {duration_hours, amount_owed_cents} = calculate_session_derived_fields(session);
            return (
                <tr key={index}
                    className={'clickable-row'}
                    onClick={() => props.history.push(`/manager/sessions/${session.sessionId}`)}>
                    <td>{session.student.firstName} {session.student.lastName}</td>
                    <td>{session.subject}</td>
                    <td>{session.tutor.user.firstName} {session.tutor.user.lastName}</td>
                    <td>{moment(session.startTime).format("MM/DD/YYYY hh:mm A")}</td>
                    <td>{moment(session.endTime).format("MM/DD/YYYY hh:mm A")}</td>
                    <td>{duration_hours.toFixed(2)}h</td>
                    <td>${(amount_owed_cents / 100).toFixed(2)}</td>
                </tr>
            );
        };
        return (
            <Table responsive striped>
                <thead>
                <tr>
                    <th>Student</th>
                    <th>Subject</th>
                    <th>Tutor</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration</th>
                    <th>Amount Owed</th>
                </tr>
                </thead>
                <tbody>
                {payment.sessions.map(render_session_row)}
                <tr>
                    <td colSpan={5} className={'fw-bold text-end'}>Total</td>
                    <td>{total_duration_hours.toFixed(2)}h</td>
                    <td>${(total_amount_owed_cents / 100).toFixed(2)}</td>
                </tr>
                </tbody>
            </Table>
        );
    };

    return (
        <Col>
            <Helmet>
                <title>Payment | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Payment</h1>
            <PaymentBasicDetails payment={payment}/>
            <h2>Sessions in Payment</h2>
            {render_sessions_table()}
            <h2>Audit Trail</h2>
            <PaymentAuditTrailTable audit_trail={payment.auditTrail}/>
        </Col>
    )
};

export default connector(ManagerPaymentPage);
