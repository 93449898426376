import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Col} from "react-bootstrap";

import {get_navigation_items, NavigationDataItem} from '../NavigationData';
import StateType from "../../../store/StateType";
import DesktopNavigationItem from "./DesktopNavigationItem/DesktopNavigationItem";

const mapStateToProps = (state: StateType) => ({
    is_registered_tutor: state.account.is_registered_tutor
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const DesktopNavigation = (props: PropsFromRedux) => {
    const nav_items: NavigationDataItem[] = get_navigation_items(props.is_registered_tutor);
    const render_nav_items = () => {
        return nav_items.map((nav_item, index) => (
            <DesktopNavigationItem path={nav_item.path} title={nav_item.title} icon={nav_item.icon} key={index}/>
        ));
    };
    return (
        <Col className={'d-none d-md-block'} md={{span: 2}}>
            {render_nav_items()}
        </Col>
    );
};

export default connector(DesktopNavigation);
