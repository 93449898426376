import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Button, Col, Table} from "react-bootstrap";
import moment from "moment";
import * as actions from '../../store/actions';
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../api/ManagerAPI";

const map_dispatch_to_props = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, map_dispatch_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IManagerInvitesProps extends PropsFromRedux, RouteComponentProps {
}

const ManagerInvites = (props: IManagerInvitesProps) => {

    const [manager_invites, set_manager_invites] = useState<StudentManagers.StudentManagerInviteEntityDTO[]>([]);
    const [loading, set_loading] = useState<boolean>(true);

    const load_invites = async () => {
        const invites = await ManagerAPI.getInvites();
        set_manager_invites(invites);
    };

    useEffect(() => {
        load_invites().then(() => set_loading(false));
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    if (!loading && manager_invites.length === 0) {
        return (
            <Col>
                <h1>Tiny Country Tutoring</h1>
                <p>You have no pending invites. Use the controls on the left or bottom of your screen to enter the
                    system!</p>
            </Col>
        );
    }

    const respondToInvite = async (invite: StudentManagers.StudentManagerInviteEntityDTO, status: 'accept' | 'reject') => {
        if (loading) return;
        set_loading(true);

        const accept = status === 'accept';

        try {
            await ManagerAPI.respondToInvite({
                studentId: invite.student.studentId,
                accept,
            })
        } catch (e) {
            props.set_error('Unable to accept invite.', e);
        }

        if (accept) {
            props.history.push(`/manager/students/${invite.student.studentId}`);
            return;
        }
        await load_invites();
        set_loading(false);
    }

    const render_invite_table = () => {
        const render_invite_row = (invite: StudentManagers.StudentManagerInviteEntityDTO, index: number) => (
            <tr key={index}>
                <td>{moment(invite.inviteDate).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{invite.tutor.user.firstName} {invite.tutor.user.lastName}</td>
                <td><a href={`mailto:${invite.tutor.user.email}`}>{invite.tutor.user.email}</a></td>
                <td>{invite.student.firstName} {invite.student.lastName}</td>
                <td>{invite.relation}</td>
                <td>
                    <Button
                        className={"me-2"}
                        variant={'success'}
                        type={'button'}
                        disabled={loading}
                        onClick={() => respondToInvite(invite, 'accept')}>Accept</Button>
                    <Button
                        variant={'danger'}
                        type={'button'}
                        disabled={loading}
                        onClick={() => respondToInvite(invite, 'reject')}>Reject</Button>
                </td>
            </tr>
        );
        return (
            <Table responsive striped>
                <thead>
                <tr>
                    <th>Invitation Date</th>
                    <th>Tutor Name</th>
                    <th>Tutor Email</th>
                    <th>Student Name</th>
                    <th>Relation to Student</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {manager_invites.map(render_invite_row)}
                </tbody>
            </Table>
        );
    };

    return (
        <Col>
            <Helmet>
                <title>Tiny Country Tutoring</title>
            </Helmet>
            <h1>Tiny Country Tutoring</h1>
            {render_invite_table()}
        </Col>
    );
};

export default connector(ManagerInvites);
