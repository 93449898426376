import React from "react";
import {calculate_payment_derived_fields, payment_creation_date, payment_students} from "../../data/Payment";
import moment from "moment/moment";
import InfiniteScrollingTable, {InfiniteScrollingTableProps} from "../InfiniteScrollingTable/InfiniteScrollingTable";

type Props = Omit<InfiniteScrollingTableProps<Payments.TutoringPaymentEntityDTO>, 'thead' | 'item_row' | 'items'> & {
    on_payment_click: (session: Payments.TutoringPaymentEntityDTO) => void;
    payments: Payments.TutoringPaymentEntityDTO[]
};

const PaymentRow = ({on_payment_click, payment}: Props & { payment: Payments.TutoringPaymentEntityDTO }) => {
    const creation_date = moment(payment_creation_date(payment));
    const {total_duration_hours, total_amount_owed_cents} = calculate_payment_derived_fields(payment);
    return (
        <tr key={payment.paymentId} className={'clickable-row'} onClick={() => on_payment_click(payment)}>
            <td>{creation_date.format('MM/DD/YYYY hh:mm a')}</td>
            <td>{payment_students(payment).join(', ')}</td>
            <td>{payment.sessions.length}</td>
            <td>{payment.method}</td>
            <td>{total_duration_hours.toFixed(2)} h</td>
            <td>${(total_amount_owed_cents / 100).toFixed(2)}</td>
            <td>{payment.paid ? 'Paid' : 'Unpaid'}</td>
        </tr>
    );
};

const TutorPaymentsTable = (props: Props) => {
    const {load_more, payments, has_more} = props;
    return (
        <InfiniteScrollingTable
            items={payments}
            load_more={load_more}
            has_more={has_more}
            thead={(
                <thead>
                <tr>
                    <th>Date Created</th>
                    <th>Students</th>
                    <th>Total Sessions</th>
                    <th>Payment Method</th>
                    <th>Total Duration</th>
                    <th>Total Amount Owed</th>
                    <th>Payment Status</th>
                </tr>
                </thead>
            )}
            item_row={payment => <PaymentRow key={payment.paymentId} payment={payment} {...props}/>}/>
    )
};

export default TutorPaymentsTable;
