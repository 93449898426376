import React from "react";
import {LinkContainer} from 'react-router-bootstrap';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {NavigationDataItem} from "../../NavigationData";

const DesktopNavigationItem = (props: NavigationDataItem) => (
    <Row className={'mb-3'}>
        <Col className={'d-grid'}>
            <LinkContainer
                to={props.path}>
                <Button variant={'outline-dark'}>
                    <FontAwesomeIcon icon={props.icon} fixedWidth/> {props.title}
                </Button>
            </LinkContainer>
        </Col>
    </Row>
)

export default DesktopNavigationItem;
