import React from "react";
import TextInputType from "../../../data/ui/input/TextInputType";
import {Form} from "react-bootstrap";

interface TextElementProps {
    element: TextInputType;
    changed (event: any) : void;
}

const TextElement = (props: TextElementProps) => (
    <Form.Control
        type="text"
        placeholder={props.element.placeholder || props.element.label || ''}
        value={props.element.value}
        onChange={props.changed}
        isInvalid={!props.element.valid && props.element.touched}
        isValid={props.element.valid} />
);

export default TextElement;
