import React, {FormEvent, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps, useParams} from "react-router";
import {Accordion, Alert, Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faUpload} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {Helmet} from "react-helmet";
import * as actions from '../../../store/actions';
import * as validators from '../../../util/validators';
import * as filters from '../../../util/filters';
import * as forms from '../../../util/forms'
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import {calculate_session_derived_fields} from "../../../data/Session";
import SessionDetails from "../../../components/SessionDetails/SessionDetails";
import PaymentBasicDetails from "../../../components/PaymentBasicDetails/PaymentBasicDetails";
import InputType, {InputTypes} from "../../../data/ui/input/InputType";
import TextInputType from "../../../data/ui/input/TextInputType";
import TextElement from "../../../components/InputElement/TextElement/TextElement";
import SelectButtonInputType from "../../../data/ui/input/SelectButtonInputType";
import SelectButtonElement from "../../../components/InputElement/SelectButtonElement/SelectButtonElement";
import SingleTypeAheadInputType from "../../../data/ui/input/SingleTypeAheadInputType";
import DateTimeInputType from "../../../data/ui/input/DateTimeInputType";
import CurrencyInputType from "../../../data/ui/input/CurrencyInputType";
import TitleValuePairRow from "../../../components/TitleValuePairRow/TitleValuePairRow";
import InputElement from "../../../components/InputElement/InputElement";
import SessionAttachmentItem from "../../../components/SessionAttachmentItem/SessionAttachmentItem";
import {FileInputType} from "../../../data/ui/input/FileInputType";
import FileElement from "../../../components/InputElement/FileElement/FileElement";
import TutorAPI from "../../../api/TutorAPI";
import {useInterval} from "usehooks-ts";

const mapDispatchToProps = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ISessionPageProps extends PropsFromRedux, RouteComponentProps {

}

interface IEditSessionForm extends Record<string, InputType> {
    subject: SingleTypeAheadInputType;
    startTime: DateTimeInputType;
    endTime: DateTimeInputType;
    hourlyRate: CurrencyInputType;
}

interface INewAttachmentForm extends Record<string, InputType> {
    attachment_type: SelectButtonInputType<number>;
    attachment_link: TextInputType;
}

interface IUploadAttachmentForm extends Record<string, InputType> {
    file: FileInputType;
}

const attachment_type_map: Record<number, string> = {
    1: 'YouTube Upload',
    2: 'PDF Link',
    3: 'Other'
}

const TutorSessionPage = (props: ISessionPageProps) => {

    const params = useParams<{ session_id?: string | undefined }>();
    const [loading, set_loading] = useState<boolean>(true);
    const [session, set_session] = useState<Sessions.TutoringSessionEntityDTO | null>(null);
    const [attachments, set_attachments] = useState<Sessions.SessionAttachmentEntity[]>([]);
    const [payment, set_payment] = useState<Payments.TutoringPaymentEntityDTO | null>(null);
    const [pdfRequestsCounts, setPdfRequestsCounts] = useState<ActiveFailedRequestCounts>({
        failedRequests: 0,
        activeRequests: 0
    });
    const [hasActivePdfRequests, setHasActivePdfRequests] = useState<boolean>(false);

    const [edit_session_form, set_edit_session_form] = useState<IEditSessionForm>({
        subject: {
            type: InputTypes.SingleTypeAhead,
            touched: false,
            valid: false,
            id: 'subject-typeahead',
            value: [],
            allow_new: true,
            options: []
        },
        startTime: {
            type: InputTypes.DateTime,
            touched: false,
            valid: false,
            value: moment().startOf('minute'),
            date_format: 'MM/DD/YYYY',
            time_format: 'hh:mm A',
            filter: (value: moment.Moment) => value.startOf('minute')
        },
        endTime: {
            type: InputTypes.DateTime,
            touched: false,
            valid: false,
            value: moment().startOf('minute'),
            date_format: 'MM/DD/YYYY',
            time_format: 'hh:mm A',
            filter: (value: moment.Moment) => value.startOf('minute')
        },
        hourlyRate: {
            type: InputTypes.Currency,
            touched: false,
            valid: true,
            value: 0,
            allow_negative: false,
            step: 1,
            validator: (value: number) => value >= 0
        }
    });
    const [edit_session_valid, set_edit_session_valid] = useState<boolean>(false);

    const [new_attachment_form, set_new_attachment_form] = useState<INewAttachmentForm>({
        attachment_type: {
            type: InputTypes.SelectButton,
            touched: true,
            valid: true,
            label: 'Attachment Type',
            options: Object.keys(attachment_type_map).map(attachment_type_id => ({
                name: attachment_type_map[Number(attachment_type_id)],
                value: Number(attachment_type_id)
            })),
            value: 1,
            variant: 'outline-secondary',
            selected_value_to_string: value => attachment_type_map[value],
            id: 'new_attachment_type_dropdown'
        },
        attachment_link: {
            type: InputTypes.Text,
            touched: false,
            valid: false,
            label: 'Attachment Link',
            validator: validators.requiredText,
            filter: filters.remove_spaces,
            value: ''
        }
    });

    const [new_attachment_form_valid, set_new_attachment_form_valid] = useState<boolean>(false);

    const [upload_attachment_form, set_upload_attachment_form] = useState<IUploadAttachmentForm>({
        file: {
            type: InputTypes.File,
            touched: false,
            valid: false,
            accept: 'application/pdf',
            validator: (value) => !!value,
            value: null
        }
    });
    const [upload_attachment_form_valid, set_upload_attachment_form_valid] = useState<boolean>(false);

    const load_session = async (session_id: number) => {
        try {
            set_loading(true);
            const post_data = await TutorAPI.sessionPage(session_id);
            await loadPdfRequestsCounts();
            set_session(post_data.session);
            set_attachments(post_data.sessionAttachments);
            set_payment(post_data.payment);

            const subjects = await TutorAPI.subjects();

            const session_form: IEditSessionForm = {
                subject: {
                    ...edit_session_form.subject,
                    options: [...subjects],
                    value: [post_data.session.subject]
                } as SingleTypeAheadInputType,
                startTime: {
                    ...edit_session_form.startTime,
                    value: moment(post_data.session.startTime)
                } as DateTimeInputType,
                endTime: {
                    ...edit_session_form.endTime,
                    value: moment(post_data.session.endTime)
                } as DateTimeInputType,
                hourlyRate: {
                    ...edit_session_form.hourlyRate,
                    value: post_data.session.rateCents / 100
                } as CurrencyInputType
            };

            const {form, form_valid} = forms.filter_and_validate_form(session_form);
            set_edit_session_form(form as IEditSessionForm);
            set_edit_session_valid(form_valid);

            set_loading(false);
        } catch (e) {
            props.set_error('Unable to load session.', e);
            props.history.push('/tutor/sessions');
        }
    };

    const loadPdfRequestsCounts = async () => {
        const counts = await TutorAPI.pdfUploadRequestCounts();
        setPdfRequestsCounts(counts);
    }

    const load_session_from_params = async () => {
        const session_id: number = Number(params.session_id);
        if (isNaN(session_id)) {
            props.set_error('Invalid session id.', null);
            props.history.push('/tutor/sessions');
            return;
        }
        await load_session(session_id);
    };

    useEffect(() => {
        load_session_from_params();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (hasActivePdfRequests && pdfRequestsCounts.activeRequests === 0) {
            setHasActivePdfRequests(false);
            load_session_from_params().then();
        }
        if (!hasActivePdfRequests && pdfRequestsCounts.activeRequests > 0) {
            setHasActivePdfRequests(true);
        }
    }, [pdfRequestsCounts.activeRequests, hasActivePdfRequests]);

    useInterval(() => {
        loadPdfRequestsCounts().then();
    }, pdfRequestsCounts.activeRequests > 0 ? 5 * 1000 : null);

    if (loading || !session) {
        return (
            <Col>
                <LoadingScreen/>
            </Col>
        );
    }

    const render_session_with_payment = () => {
        if (!payment) return null;
        return (
            <>
                <SessionDetails session={session}/>
                <Alert variant={'warning'}>
                    The session details above cannot be modified while session is attached to a payment.
                </Alert>
                <h2>Payment</h2>
                <PaymentBasicDetails payment={payment}/>
                <LinkContainer to={`/tutor/payments/${payment.paymentId}`}>
                    <Button type={'button'}>
                        Payment Details
                    </Button>
                </LinkContainer>
            </>
        );
    };

    const update_edit_session_form = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, edit_session_form);
        set_edit_session_form(form as IEditSessionForm);
        set_edit_session_valid(form_valid);
    };

    const submit_edit_session_form = async (event: FormEvent) => {
        event.preventDefault();
        if (loading || !session) return;
        const {form, form_valid} = forms.filter_and_validate_form(edit_session_form);
        const final_form = form as IEditSessionForm;
        if (!form_valid) {
            set_edit_session_form(final_form);
            set_edit_session_valid(form_valid);
            return;
        }
        if (!final_form.subject.value || final_form.subject.value.length === 0) {
            props.set_error('Please select a subject.', null);
            return;
        }
        try {
            const subject_value = final_form.subject.value[0];
            set_loading(true);
            await TutorAPI.updateSession({
                sessionId: session.sessionId,
                subject: typeof subject_value === 'string' ? subject_value : `${subject_value.label}`,
                startTime: moment(final_form.startTime.value).utc().format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(final_form.endTime.value).utc().format('YYYY-MM-DD HH:mm:ss'),
                rateCents: Math.round(final_form.hourlyRate.value * 100)
            });
            await load_session_from_params();
        } catch (e) {
            props.set_error('Unable to update session.', e);
        }
        set_loading(false);
    };

    const render_session_without_payment = () => {
        const session_to_display: Sessions.TutoringSessionEntityDTO = {
            ...session,
            endTime: moment(edit_session_form.endTime.value).utc().format(),
            startTime: moment(edit_session_form.startTime.value).utc().format(),
            rateCents: edit_session_form.hourlyRate.value * 100
        };
        const {amount_owed_cents, duration_hours} = calculate_session_derived_fields(session_to_display);
        const render_form_element = (key: string, index: number) => {
            const label = key.replace(/_/g, ' ')
                .replace(/(^.|\s.)/g, (str: string) => str.toUpperCase());
            return (
                <Row key={index} className={'mb-2'}>
                    <Col xs={12} md={2} className={'fw-bold'}>{label}</Col>
                    <Col>
                        <InputElement
                            element={edit_session_form[key]}
                            changed={(event) => update_edit_session_form(key, event)}/>
                    </Col>
                </Row>
            );
        };
        return (
            <Form onSubmit={submit_edit_session_form}>
                <TitleValuePairRow
                    title={'Student'}
                    value={session.student.firstName + ' ' + session.student.lastName}/>
                {Object.keys(edit_session_form).map(render_form_element)}
                <TitleValuePairRow title={'Duration'} value={`${duration_hours.toFixed(2)} h`}/>
                <TitleValuePairRow title={'Amount Owed'} value={`$${(amount_owed_cents / 100).toFixed(2)}`}/>
                <Row>
                    <Col>
                        <Button
                            className={'me-2'}
                            variant={'success'}
                            disabled={loading || !edit_session_valid}
                            type={'submit'}>Save</Button>
                        <Button
                            variant={'secondary'}
                            type={'button'}
                            onClick={() => props.history.goBack()}>Cancel</Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    const new_attachment_change = (key: string, event: any) => {
        const {form, form_valid} = forms.update_form(event, key, new_attachment_form);
        set_new_attachment_form(form as INewAttachmentForm);
        set_new_attachment_form_valid(form_valid)
    };

    const new_attachment_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (loading) return;
        const {form, form_valid} = forms.filter_and_validate_form(new_attachment_form);
        const final_form = form as INewAttachmentForm;
        if (!form_valid) {
            set_new_attachment_form(final_form);
            set_new_attachment_form_valid(form_valid);
            return;
        }
        set_loading(true);
        try {
            const new_attachment = await TutorAPI.createSessionAttachment({
                sessionId: Number(params.session_id),
                attachmentType: final_form.attachment_type.value,
                attachmentUrl: final_form.attachment_link.value
            });
            set_attachments([...attachments, new_attachment]);
            const updated_form: INewAttachmentForm = {
                ...final_form,
                attachment_type: {
                    ...final_form.attachment_type,
                    value: 1
                },
                attachment_link: {
                    ...final_form.attachment_link,
                    touched: false,
                    valid: false,
                    value: ''
                }
            };
            set_new_attachment_form(updated_form);
            set_new_attachment_form_valid(false);
        } catch (e) {
            props.set_error('Unable to create attachment.', e);
        }
        set_loading(false);
    };

    const upload_attachment_change = (event: any) => {
        const {form, form_valid} = forms.update_form(event, 'file', upload_attachment_form);
        set_upload_attachment_form(form as IUploadAttachmentForm);
        set_upload_attachment_form_valid(form_valid);
    };

    const upload_attachment_submit = async (event: FormEvent) => {
        event.preventDefault();
        if (loading || !session) return;
        const {form, form_valid} = forms.filter_and_validate_form(upload_attachment_form);
        const final_form = form as IUploadAttachmentForm;
        if (!form_valid) {
            set_upload_attachment_form(final_form);
            set_upload_attachment_form_valid(form_valid);
            return;
        }

        set_loading(true);

        try {
            const {url: uploadUrl} = await TutorAPI.requestPDFUploadURL(session.sessionId);
            await TutorAPI.uploadPDF(uploadUrl, final_form.file.value!);
            await load_session(session.sessionId);
        } catch (e) {
            props.set_error('Unable to upload attachment.', e);
        } finally {
            set_loading(false);
        }

    };

    const render_attachments = () => {
        const render_attachment = (attachment: Sessions.SessionAttachmentEntity, index: number) => (
            <SessionAttachmentItem attachment={attachment} eventKey={`attachment_${index}`} key={index}/>
        );
        const renderUploadAttachmentForm = () => {
            if (pdfRequestsCounts.failedRequests > 0) {
                return (
                    <Alert variant={'danger'}>
                        You have {pdfRequestsCounts.failedRequests} failed PDF upload requests. Please try again later.
                    </Alert>
                );
            }
            if (pdfRequestsCounts.activeRequests > 0) {
                return (
                    <Alert variant={'warning'}>
                        You have {pdfRequestsCounts.activeRequests} active PDF upload requests. Please wait for them to
                        finish before uploading another PDF.
                    </Alert>
                );
            }
            return (
                <Form onSubmit={upload_attachment_submit}>
                    <Form.Group>
                        <InputGroup>
                            <FileElement element={upload_attachment_form.file} changed={upload_attachment_change}/>
                            <Button
                                type={'submit'}
                                variant={'success'}
                                disabled={!upload_attachment_form_valid}>
                                <FontAwesomeIcon icon={faUpload} fixedWidth/>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Form>
            )
        };
        return (
            <>
                <h2 className={'mt-3'}>Attachments</h2>
                <Accordion>
                    {attachments.map(render_attachment)}
                </Accordion>
                <h3 className={'mt-2'}>New Attachment</h3>
                <Form onSubmit={new_attachment_submit}>
                    <Form.Group>
                        <InputGroup>
                            <SelectButtonElement
                                element={new_attachment_form.attachment_type}
                                changed={(event) => new_attachment_change('attachment_type', event)}/>
                            <TextElement
                                element={new_attachment_form.attachment_link}
                                changed={(event) => new_attachment_change('attachment_link', event)}/>
                            <Button
                                type={'submit'}
                                variant={'success'}
                                disabled={!new_attachment_form_valid}>
                                <FontAwesomeIcon icon={faPlus} fixedWidth/>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Form>
                <h3 className={'mt-2'}>Upload Attachment</h3>
                {renderUploadAttachmentForm()}
            </>
        );
    };

    return (
        <Col>
            <Helmet>
                <title>Session Details | Tiny Country Tutoring</title>
            </Helmet>
            <h1>Session Details</h1>
            {payment ? render_session_with_payment() : render_session_without_payment()}
            {render_attachments()}
        </Col>
    );
}

export default connector(TutorSessionPage);
