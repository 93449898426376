import React from "react";
import {LinkContainer} from 'react-router-bootstrap';
import {Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './MobileNavigationItem.css';

import {NavigationDataItem} from "../../NavigationData";

const MobileNavigationItem = (props: NavigationDataItem) => (
    <Nav.Item>
        <LinkContainer to={props.path}>
            <Nav.Link><FontAwesomeIcon icon={props.icon} size={'sm'}/></Nav.Link>
        </LinkContainer>
    </Nav.Item>
);

export default MobileNavigationItem;
