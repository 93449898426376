import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Col} from "react-bootstrap";
import moment from "moment";
import * as actions from "../../store/actions";
import PagedEntityList from "../../components/PagedEntityList/PagedEntityList";
import {calculate_payment_derived_fields, payment_creation_date, payment_students} from "../../data/Payment";
import {Helmet} from "react-helmet";
import ManagerAPI from "../../api/ManagerAPI";

const mapDispatchToProps = (dispatch: any) => ({
    set_error: (default_message: string, e: any) => dispatch(actions.set_error_from_response(default_message, e))
});

const connector = connect(null, mapDispatchToProps);

interface IManagerPaymentsProps extends ConnectedProps<typeof connector>, RouteComponentProps {
}

const ManagerPayments = (props: IManagerPaymentsProps) => {
    const [payments, set_payments] = useState<Payments.TutoringPaymentEntityDTO[]>([]);

    const [current_page, set_current_page] = useState<number>(0);

    const [max_page, set_max_page] = useState<number>(0);

    const [has_more, set_has_more] = useState<boolean>(true);

    const [loading, set_loading] = useState<boolean>(true);

    const load_page = async (page: number) => {
        if (!loading) set_loading(true);
        try {
            const list = await ManagerAPI.paymentsPage({
                pageSize: Number(process.env.REACT_APP_PAGE_SIZE),
                page: page
            });
            if (max_page <= list.page) {
                set_max_page(list.page);
                set_has_more(list.hasMore);
            }
            set_current_page(list.page);
            set_payments(list.items);
        } catch (e) {
            props.set_error('Unable to load sessions.', e);
        }
        set_loading(false);
    };

    useEffect(() => {
        load_page(current_page)
            .then(() => set_loading(false))
        // eslint-disable-next-line
    }, []);

    const render_table_heading = () => (
        <tr>
            <th>Date Created</th>
            <th>Students</th>
            <th>Total Sessions</th>
            <th>Payment Method</th>
            <th>Total Duration</th>
            <th>Total Amount Owed</th>
            <th>Payment Status</th>
        </tr>
    );

    const render_payment_row = (payment: Payments.TutoringPaymentEntityDTO, index: number) => {
        const creation_date = payment_creation_date(payment);
        const {total_duration_hours, total_amount_owed_cents} = calculate_payment_derived_fields(payment);
        return (
            <tr key={index}
                className={'clickable-row'}
                onClick={() => props.history.push(`/manager/payments/${payment.paymentId}`)}>
                <td>{moment(creation_date).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{payment_students(payment)}</td>
                <td>{payment.sessions.length}</td>
                <td>{payment.method}</td>
                <td>{total_duration_hours.toFixed(2)}h</td>
                <td>${(total_amount_owed_cents / 100).toFixed(2)}</td>
                <td>{payment.paid ? 'Paid' : 'Unpaid'}</td>
            </tr>
        );
    };

    return (<Col>
        <Helmet>
            <title>My Payments | Tiny Country Tutoring</title>
        </Helmet>
        <h1>My Payments</h1>
        <PagedEntityList<Payments.TutoringPaymentEntityDTO>
            items={payments}
            current_page={current_page}
            max_page={max_page}
            has_more={has_more}
            loading={loading}
            load_page={load_page}
            render_table_heading={render_table_heading}
            render_item_row={render_payment_row}/>
    </Col>);
}

export default connector(ManagerPayments);
