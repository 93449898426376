import WebAPI from "./WebAPI";
import {Pagination} from "../data/Pagination";
import axios from "axios";

type IsTutorResult = { isTutor: boolean };
export default class TutorAPI extends WebAPI {

    static async isTutor(): Promise<boolean> {
        return (await axios.get<IsTutorResult>('/tutoring/tutor/is_tutor')).data.isTutor;
    }

    static async me(): Promise<Tutors.TutorEntityDTO> {
        return (await axios.get<Tutors.TutorEntityDTO>('/tutoring/tutor/profile')).data;
    }

    static async register(event: Tutors.RegisterTutorRequest): Promise<Tutors.TutorEntityDTO> {
        return (await axios.post<Tutors.TutorEntityDTO>('/tutoring/tutor/register', event)).data;
    }

    static async students(): Promise<Students.StudentEntityDTO[]> {
        return (await axios.post<Students.StudentEntityDTO[]>('tutoring/tutor/students')).data;
    }

    static async registerStudent(request: Students.RegisterStudentRequest): Promise<Students.StudentEntityDTO> {
        return (await axios.post<Students.StudentEntityDTO>('tutoring/tutor/students/register', request)).data;
    }

    static async studentPage(studentId: number): Promise<Students.TutorStudentPageModel> {
        return (await axios.get<Students.TutorStudentPageModel>(`/tutoring/tutor/students/page?${new URLSearchParams({studentId: `${studentId}`})}`)).data;
    }

    static async updateStudent(request: Students.EditStudentRequest): Promise<Students.StudentEntityDTO> {
        return (await axios.post<Students.StudentEntityDTO>('tutoring/tutor/students/update', request)).data;
    }

    static async sessionsPage(request: ListPageRequest): Promise<Sessions.TutoringSessionEntityDTOPagedList> {
        return (await axios.get<Sessions.TutoringSessionEntityDTOPagedList>(`/tutoring/tutor/sessions?${Pagination.listPageRequestToParams(request)}`)).data;
    }

    static async subjects(): Promise<string[]> {
        return (await axios.get<string[]>('/tutoring/tutor/sessions/subjects')).data;
    }

    static async createSession(request: Sessions.CreateTutoringSessionRequest): Promise<Sessions.TutoringSessionEntityDTO> {
        return (await axios.post<Sessions.TutoringSessionEntityDTO>('/tutoring/tutor/sessions/create', request)).data;
    }

    static async sessionPage(sessionId: number): Promise<Sessions.TutoringSessionPageModel> {
        return (await axios.get<Sessions.TutoringSessionPageModel>(`/tutoring/tutor/sessions/page?${new URLSearchParams({sessionId: `${sessionId}`})}`)).data;
    }

    static async updateSession(request: Sessions.EditTutoringSessionRequest): Promise<Sessions.TutoringSessionEntityDTO> {
        return (await axios.post<Sessions.TutoringSessionEntityDTO>('/tutoring/tutor/sessions/update', request)).data;
    }

    static async createSessionAttachment(request: Sessions.CreateSessionAttachmentRequest): Promise<Sessions.SessionAttachmentEntity> {
        return (await axios.post<Sessions.SessionAttachmentEntity>('/tutoring/tutor/sessions/attachments/create', request)).data;
    }

    static async paymentsPage(request: ListPageRequest): Promise<Payments.TutoringPaymentEntityDTOPaginatedList> {
        return (await axios.get<Payments.TutoringPaymentEntityDTOPaginatedList>(`/tutoring/tutor/payments?${Pagination.listPageRequestToParams(request)}`)).data;
    }

    static async sessionsWithoutPayment(): Promise<Sessions.TutoringSessionEntityDTO[]> {
        return (await axios.get<Sessions.TutoringSessionEntityDTO[]>('/tutoring/tutor/sessions/no-pay')).data;
    }

    static async createPayment(request: Payments.CreateTutoringPaymentRequest): Promise<Payments.TutoringPaymentEntityDTO> {
        return (await axios.post<Payments.TutoringPaymentEntityDTO>('/tutoring/tutor/payments/create', request)).data;
    }

    static async loadPayment(paymentId: number): Promise<Payments.TutoringPaymentEntityDTO> {
        return (await axios.get<Payments.TutoringPaymentEntityDTO>(`/tutoring/tutor/payments/page?${new URLSearchParams({paymentId: `${paymentId}`})}`)).data;
    }

    static async updatePayment(request: Payments.UpdateTutoringPaymentRequest): Promise<Payments.TutoringPaymentEntityDTO> {
        return (await axios.post<Payments.TutoringPaymentEntityDTO>('/tutoring/tutor/payments/update', request)).data;
    }

    static async requestPDFUploadURL(sessionId: number): Promise<{ url: string }> {
        return (await axios.get<{
            url: string
        }>(`/tutoring/tutor/sessions/attachments/upload/request/${sessionId}`)).data;
    }

    static async uploadPDF(presignedUrl: string, file: File): Promise<void> {
        await WebAPI.externalAxiosInstance().put(presignedUrl, file);
    }

    static async pdfUploadRequestCounts(): Promise<ActiveFailedRequestCounts> {
        return (await axios.get<ActiveFailedRequestCounts>('/tutoring/tutor/sessions/attachments/upload/status')).data;
    }

    static async inviteStudentManager(request: StudentManagers.InviteStudentManagerRequest): Promise<StudentManagers.StudentManagerInviteEntityDTO> {
        return (await axios.post<StudentManagers.StudentManagerInviteEntityDTO>('/tutoring/tutor/managers/invite', request)).data;
    }

}
