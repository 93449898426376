import React from "react";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {calculate_session_derived_fields, calculate_session_list_totals} from "../../data/Session";

interface ITutorPaymentSessionsTableProps {
    sessions: Sessions.TutoringSessionEntityDTO[];
    add_to_payment: boolean;
    show_total: boolean;
    payment?: Payments.TutoringPaymentEntityDTO;

    add_session_to_payment(session: Sessions.TutoringSessionEntityDTO): void;

    remove_session_from_payment(session: Sessions.TutoringSessionEntityDTO): void;
}

const TutorPaymentSessionsTable = (props: ITutorPaymentSessionsTableProps) => {
    const {total_duration_hours, total_amount_owed_cents} = props.show_total ?
        calculate_session_list_totals(props.sessions) :
        {
            total_duration_hours: 0,
            total_amount_owed_cents: 0
        };
    const click_action = props.add_to_payment ? props.add_session_to_payment : props.remove_session_from_payment;
    const render_session_row = (session: Sessions.TutoringSessionEntityDTO, _index: number) => {
        const {duration_hours, amount_owed_cents} = calculate_session_derived_fields(session);
        return (
            <tr key={session.sessionId}>
                <td>
                    <Button
                        variant={props.add_to_payment ? 'success' : 'danger'}
                        type={'button'}
                        onClick={() => click_action(session)}>
                        <FontAwesomeIcon icon={props.add_to_payment ? faPlus : faMinus} fixedWidth/>
                    </Button>
                </td>
                <td>{session.student.firstName} {session.student.lastName}</td>
                <td>{session.subject}</td>
                <td>{moment(session.startTime).format('MM/DD/YYYY hh:mm a')}</td>
                <td>{moment(session.endTime).format('MM/DD/YYYY hh:mm a')}</td>
                <td>{duration_hours.toFixed(2)} h</td>
                <td>${(amount_owed_cents / 100).toFixed(2)}</td>
            </tr>
        );
    };
    return (
        <Table striped responsive>
            <thead>
            <tr>
                <th/>
                <th>Student</th>
                <th>Subject</th>
                <th>Start</th>
                <th>End</th>
                <th>Duration</th>
                <th>Amount Owed</th>
            </tr>
            </thead>
            <tbody>
            {props.sessions.map(render_session_row)}
            {props.show_total ? (<tr>
                <td colSpan={5} className={'text-end fw-bold'}>Total</td>
                <td>{total_duration_hours.toFixed(2)} h</td>
                <td>${(total_amount_owed_cents / 100).toFixed(2)}</td>
            </tr>) : null}
            </tbody>
        </Table>
    );
};

export default TutorPaymentSessionsTable;

