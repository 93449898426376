import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Col, Container, Nav, Row} from "react-bootstrap";

import classes from './MobileNavigation.module.css';
import StateType from "../../../store/StateType";
import {get_navigation_items, NavigationDataItem} from "../NavigationData";
import MobileNavigationItem from "./MobileNavigationItem/MobileNavigationItem";

const map_state_to_props = (state: StateType) => ({
    is_registered_tutor: state.account.is_registered_tutor
});

const connector = connect(map_state_to_props);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MobileNavigation = (props: PropsFromRedux) => {
    const nav_items = get_navigation_items(props.is_registered_tutor);
    const render_nav_item = (nav_item: NavigationDataItem, index: number) => (
        <MobileNavigationItem path={nav_item.path} title={nav_item.title} icon={nav_item.icon} key={index}/>
    );
    return (
        <Container className={['fixed-bottom', 'd-md-none', classes.MobileNavigationRow].join(' ')} fluid>
            <Row>
                <Col>
                    <Nav justify variant={'pills'}>
                        {nav_items.map(render_nav_item)}
                    </Nav>
                </Col>
            </Row>
        </Container>
    );
};

export default connector(MobileNavigation);
