import React from "react";
import CurrencyInput from "react-currency-input-field";

import CurrencyInputType from "../../../data/ui/input/CurrencyInputType";

interface CurrencyInputElementProps {
    element: CurrencyInputType;

    onChange(event: any): void;
}

const CurrencyInputElement = (props: CurrencyInputElementProps) => (
    <CurrencyInput
        allowNegativeValue={props.element.allow_negative}
        prefix={'$'}
        onValueChange={(value: any) => !isNaN(Number(value)) && props.onChange({target: {value: Number(value)}})}
        className={'form-control'}
        step={props.element.step}
        value={props.element.value}/>
);

export default CurrencyInputElement;
