import axios, {AxiosError} from "axios";

export default class WebAPI {

    protected static externalAxiosInstance(baseUrl?: string) {
        return axios.create({
            ...(baseUrl ? {baseURL: baseUrl} : {}),
        });
    }

    static errorMessage(error: any, _fallback?: string): string {
        const fallback = _fallback ?? 'Unknown error';
        if (!error) return fallback;
        if (!(error as AxiosError).isAxiosError) {
            return error.message ?? fallback;
        }
        const axiosError = error as AxiosError;
        if (!axiosError.response) return fallback;
        if (!axiosError.response.data) return fallback;
        const responseData = axiosError.response.data as any;
        if (typeof responseData === 'string') return responseData;
        if (responseData.message) return responseData.message;
        return fallback;
    }

    static isUnauthorized(error: any): boolean {
        if (!error) return false;
        if (!(error as AxiosError).isAxiosError) return false;
        const axiosError = error as AxiosError;
        if (!axiosError.response) return false;
        return axiosError.response.status === 403;
    }

}
