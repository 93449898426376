import React, {useState} from "react";
import DateTime from 'react-datetime';

import DateTimeInputType from "../../../data/ui/input/DateTimeInputType";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

interface DateTimeElementProps {
    element: DateTimeInputType,

    change(event: any): void;
}

const DateTimeElement = ({change, element}: DateTimeElementProps) => {
    const [input_state, set_input_state] = useState<string | null>(null);

    const on_change = (value: moment.Moment | string) => {
        change({target: {value: value}});
        set_input_state(null);
    };

    const on_input_blur = () => {
        if (!input_state) return;
        const input_moment = moment(input_state);
        if (input_moment.isValid()) {
            on_change(input_moment);
        }
    };

    const render_input = () => (input_props: any, openCalendar: Function, closeCalendar: Function) => (
        <InputGroup>
            <Form.Control
                {...input_props}
                value={input_state !== null ? input_state : input_props.value.toString()}
                onChange={(event) => set_input_state(event.target.value)}
                onBlur={on_input_blur}
            />
            <Button variant={'outline-secondary'} type={'button'} onClick={() => openCalendar()}>
                <FontAwesomeIcon icon={faCalendar} fixedWidth/>
            </Button>
            <Button variant={'outline-primary'} type={'button'} onClick={() => on_change(moment())}>
                Now
            </Button>
        </InputGroup>
    );

    return (
        <DateTime
            value={element.value}
            dateFormat={element.date_format}
            timeFormat={element.time_format}
            closeOnSelect={!!element.close_on_select}
            onChange={on_change}
            renderInput={render_input()}
        />
    );
};

export default DateTimeElement;
