import WebAPI from "./WebAPI";
import axios from "axios";

export default class AccountAPI extends WebAPI {

    static async requestCsrfToken() {
        await axios.post("/v2/users/requestCSRFToken");
    }

    static async login(event: Users.LoginEvent): Promise<Users.UserDTO> {
        return (await axios.post<Users.UserDTO>("/v2/users/login", event)).data;
    }

    static async me(): Promise<Users.UserDTO> {
        return (await axios.get<Users.UserDTO>("/users/me")).data;
    }

    static async signOut(): Promise<void> {
        await axios.post("/v2/users/signout");
    }

}
